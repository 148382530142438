import { css } from 'styled-components'; 

const approvalQueueStyles = css`
  @media screen and (max-width: 991px) {
    .flex-row-table-header {
      display: none !important;
    }
    .approval-queue,
    .recent-activity {
      .list-container {
        display: block;
        text-align: center;
        > div {
          display: block;
          width: 100% !important;
          flex: none;
        }
        ul {
          &:before {
            content: 'Consultant';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
          li {
            display: block;
            div {
              display: block;
              margin: 0 auto;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
        .allocation {
          &:before {
            content: 'Allocation';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        .requested-by {
          &:before {
            content: 'Requested By';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        .date-processed {
          &:before {
            content: 'Date Processed';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        .action {
          &:before {
            content: 'Action';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        .buttons {
          margin-top: 20px;
        }
      }
    }
  }
`;

export default approvalQueueStyles;
