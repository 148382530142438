import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import {  withRouter } from 'react-router-dom';

import Spinner from '../Common/Spinner/Spinner';

import {
  AccountStore,
  CodaEmployeesStore,
  CodaProjectsStore,
  DealOriginStore,
  FirebaseStore,
  ProjectsStore,
  RrpStore,
  SettingsStore,
  UserStore
} from '../../stores';

interface IProps extends RouteComponentProps {
  children: React.ReactNode;
  accountStore?: AccountStore;
  codaEmployeesStore?: CodaEmployeesStore;
  codaProjectsStore?: CodaProjectsStore;
  firebaseStore?: FirebaseStore;
  projectsStore?: ProjectsStore;
  rrpStore?: RrpStore;
  settingsStore?: SettingsStore;
  userStore? : UserStore;
  dealOriginStore? : DealOriginStore;
}

interface IState {
  isLoading: boolean;
  error: string | undefined;
}

@inject(
  'codaEmployeesStore',
  'codaProjectsStore',
  'firebaseStore',
  'rrpStore',
  'accountStore',
  'projectsStore',
  'settingsStore',
  'userStore',
  'dealOriginStore'
)
@observer
class Auth extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: false,
      error: undefined
    };
  }

  public async componentDidMount() : Promise<void> {
    const { accountStore } = this.props;
    if (accountStore) {
      this.initAuthStateListener();
    }
  }

  public getSixpaqData = async (): Promise<boolean> => {
    const { codaEmployeesStore, codaProjectsStore, firebaseStore, projectsStore } = this.props;
    try {
      if (firebaseStore) {
        await firebaseStore.isSixpaqInitialisedPromise;
        const token = firebaseStore.sixpaqToken;
        if (projectsStore && codaEmployeesStore && codaProjectsStore) {
          const data = { type: 'firebase', id_token: token };
          if (token) {
            const promises = [
              projectsStore.connect(data),
              codaEmployeesStore.connect(),
              codaProjectsStore.connect()
            ];
            await Promise.all(promises);
          }
        }
      }
    } catch (error) {
      this.setState({error : `Error while fetching data from Sixpaq: ${error}`});
      return false;
    }
    return true;
  };

  public getStoresData = async (): Promise<boolean> => {
    const { rrpStore, settingsStore, userStore, dealOriginStore } = this.props;
    try {
      if (rrpStore && settingsStore && userStore && dealOriginStore) {
        const promises = [settingsStore.getAppSettings(),
          userStore.fetchUsers(),
          rrpStore.getRecentRequests(),
          dealOriginStore.getDealOrigins()];
        await Promise.all(promises);
      }
    } catch (error) {
      this.setState({error : `Error while fetching data from backend: ${error}`});
      return false;
    }
    return true;
  };

  public initAuthStateListener() : void {
    const {accountStore, firebaseStore, userStore, location, history} = this.props;

    accountStore?.firebaseAuth.onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({ isLoading: true });
        let dataFetched = await this.getStoresData();
        if (!dataFetched) {
          this.setState({ isLoading: false });
          firebaseStore?.logoutUser();
          return;
        }

        dataFetched = await this.getSixpaqData();
        if (!dataFetched) {
          this.setState({ isLoading: false });
          return;
        }

        this.setState({ isLoading: false });
        const loggedInUser = userStore?.users.find(userOne => user.email === userOne.email);
        if (loggedInUser) {
          const from = location.pathname !== '/login' ? location.pathname :  '/';
          accountStore.setUser(user);
          history.replace(from);
        }
        // NOTE: Firestore rules take care of unauthenticated access
      } else {
        accountStore.setUser(undefined);
      }
    });
  }


  public render(): React.ReactNode {
    const { children } = this.props;
    const { isLoading, error } = this.state;

    if (isLoading) {
      return (
        <div className="spinner-full-page">
          <Spinner />
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-text">
          <small>{error}</small>
        </div>
      );
    }

    return children;
  }
}

export default withRouter(Auth);