import { IReportDataJson } from 'interfaces/IReportDataJson';

class TotalAllocationCalculator {
  public inputData: IReportDataJson[];

  constructor(data: IReportDataJson[]) {
    this.inputData = data;
  }

  public sum = (key: string): number => {
    const initialValue = 0;
    return this.inputData.reduce((total, currentValue) => total + parseFloat((currentValue[key] || 0)), initialValue);
  };
}

export default TotalAllocationCalculator;
