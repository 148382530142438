import { observable } from 'mobx';
import moment from 'moment';

import { INameAndEmail } from '../../interfaces/INameAndEmail';
import { IRrp } from '../../interfaces/IRrp';

export default class Rrp {
  @observable public id: string;

  @observable public approvedBy?: string | number;

  @observable public approver?: INameAndEmail;

  @observable public requestedBy: string | number;

  @observable public requestor: INameAndEmail;

  @observable public dateCreated: Date;

  // Note: this is the date the RRP was approved or rejected
  @observable public dateProcessed?: Date;

  @observable public startDate: Date;

  @observable public endDate: Date;

  @observable public hours: number;

  @observable public status: string;

  @observable public projectCode: string;

  @observable public consultant: INameAndEmail;

  @observable public consultantId: string | number;

  @observable public dealOrigin: string;

  constructor(data: IRrp) {
    this.id = data.id;
    this.approvedBy = data.approvedBy;
    this.approver = data.approver;
    this.consultant = data.consultant;
    this.consultantId = data.consultantId;
    this.dealOrigin = data.dealOrigin;
    this.requestedBy = data.requestedBy;
    this.requestor = data.requestor;
    this.dateCreated = data.dateCreated;
    this.dateProcessed = data.dateProcessed;
    this.startDate = data.startDate;
    this.endDate = data.endDate;
    this.hours = data.hours;
    this.status = data.status;
    this.projectCode = data.projectCode;
  }

  public getDateProcessedString(): string | undefined {
    return this.dateProcessed? moment.utc(this.dateProcessed).toISOString() : undefined;
  }

  public getStartDateString(): string {
    return moment.utc(this.startDate).toISOString();
  }

  public getEndDateString(): string {
    return moment.utc(this.endDate).toISOString();
  }
}
