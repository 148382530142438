import { css } from 'styled-components';

const dateSelectorStyles = css`
  .flex-row .date-selection-checkbox-container {
    display: flex;
    align-items: flex-end;
    flex: none;
    width: 170px;
    padding: 0px 0px 0px 0px;
  }
  .flex-row .date-selection-checkbox-container label > span {
    width: auto;
    height: auto;
    margin-left: 0;
    margin-right: 16px;
  }
  .flex-row .date-selection-checkbox-container label {
    margin-left: 0px;
  }
`;

export default dateSelectorStyles;
