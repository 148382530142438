import _ from 'lodash';
import { IEmployeeResponse } from 'interfaces/IEmployeeResponse';
import { IRrpUser } from 'interfaces/IRrpUser';
import { IEmployeesWithPermissions } from '../../interfaces/IEmployeesWithPermissions';

export default class Helper {
  public static getEmployeesWithPermissions(data: { employees: IEmployeeResponse[], users: IRrpUser[] })
    : IEmployeesWithPermissions[] {
    const {employees} = data;
    const {users} = data;

    const employeesWithPermissions = employees.map((employee) => {
      const user = users.find(userOne => userOne.email === employee.email);
      const permissions = user ? user.permissions : [];
      return {
        permissions,
        ...employee
      };
    });

    return _.sortBy(employeesWithPermissions, 'given_name');
  }

  public static canViewPage(user: IEmployeesWithPermissions, page: string): boolean {
    if (page === '/new-request' && !user.permissions.includes('can-create-request')) {
      return false;
    }

    if (page === '/approval-queue' && !user.permissions.includes('can-view-approve')) {
      return false;
    }

    if (page === '/reports' && !user.permissions.includes('can-view-reports')) {
      return false;
    }

    if (page === '/settings' && !user.permissions.includes('can-change-settings')) {
      return false;
    }
    return true;
  }

  public static canManageApprove(user: IEmployeesWithPermissions): boolean {
    if (!user.permissions.includes('can-manage-approve')) {
      return false;
    }

    return true;
  }

  public static formatDate(date: Date): Date {
    const d = new Date(date);
    let month = ( d.getMonth() + 1 ).toString();
    const year = d.getFullYear();

    if (month.length < 2) {
      month = `0${month}`;
    }

    return new Date(`${year}/${month}/01`);
  }

  public static calcTime (date: Date, offset: number): Date {

    // create Date object for current location
    const d = new Date(date);

    // convert to msec
    // add local time zone offset
    // get UTC time in msec
    const utc = d.getTime() + (d.getTimezoneOffset() * 60000);

    // create new Date object for different city
    // using supplied offset
    const nd = new Date(utc + (3600000*offset));

    // return date
    return  nd;
  }

  public static getYearMonth(date: Date): string {
    const localTime = this.calcTime(date, +8);

    let month = ( localTime.getMonth() + 1 ).toString();
    const year = localTime.getFullYear();

    if (month.length < 2) {
      month = `0${month}`;
    }

    return [year, month].join('-');
  }
}
