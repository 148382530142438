import { observable } from 'mobx';

export interface IProjectsJson {
  client_id: string;
  client_name: string;
  code: string;
  description: string;
  end_date: string;
  engagement_manager_id: number | null;
  entity_id: string | number;
  expiry_date: string;
  hours: number | null;
  id: string | number;
  name: string;
  start_date: string;
  billing_status: string;
}

export default class Project {
  @observable public id: string | number;

  @observable public code: string;

  @observable public name: string;

  @observable public clientName: string;

  @observable public billingStatus: string;

  constructor(data: IProjectsJson) {
    this.id = data.id;
    this.code = data.code;
    this.name = data.name;
    this.clientName = data.client_name;
    this.billingStatus = data.billing_status;
  }
}
