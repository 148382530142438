class ResponseError extends Error {
  public response: string;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response.statusText;
  }
}

export default ResponseError;
