import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import _ from 'lodash';

import { CodaEmployeesStore, Rrp } from 'stores';
import moment from 'moment';
import recentActivityStyles from './RecentActivity.styles';
import ListIdentity from '../../../../Common/ListIdentity';

const GlobalStyle = createGlobalStyle`${recentActivityStyles}`;

interface IProps {
  data: Rrp;
  codaEmployeesStore?: CodaEmployeesStore;
}

@inject('codaEmployeesStore')
@observer
class RecentActivityList extends React.Component<IProps> {

  public render(): React.ReactNode {
    const { data, codaEmployeesStore } = this.props;
    const { approver, consultant, dealOrigin, hours, status, projectCode } = data;
    const user = codaEmployeesStore?.employeesList.find(employee => employee.email === approver?.email);
    const dateProcessed = data.getDateProcessedString();
    const formattedDateProcessed = dateProcessed? dateProcessed.substring(0, 10) : '';
    const approvedByUser = user ? `${user.given_name} ${user.family_name}` : '';
    const month = moment.utc(data.startDate).format('YYYY-MM');

    return (
      <div className="list-container">
        <GlobalStyle />
        <div>
          <ListIdentity
            name={consultant.name}
            email={consultant.email}
            dealOrigin={dealOrigin}
          />
        </div>
        <div className="center-contents">
          <div className="date-processed">{formattedDateProcessed}</div>
        </div>
        <div className="center-contents">
          <div className="allocation">
            <div style={{ fontSize: '16px', lineHeight: '24px' }}>
              <span style={{ fontWeight: 'bold' }}>
                {hours}
                {' '}
                hours
              </span>
              {' '}
              on
            </div>
            <div>
              {projectCode}
            </div>
            <div>
            for
            {' '}
            {month}
            </div>
          </div>
        </div>
        <div className="center-contents">
          <div className="action">
            <span className={status} style={{ fontWeight: 'bold' }}>
              {_.capitalize(status)}
            </span>
            {' '}
            by
            {' '}
            {approvedByUser}
          </div>
        </div>
      </div>
    );
  }
}

export default RecentActivityList;
