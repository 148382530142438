import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import { IPostLedgerData } from 'interfaces/IPostLedgerData';
import Spinner from 'components/Common/Spinner/Spinner';
import newRequestStyles from './NewRequest.styles';
import { IEmployeeResponse } from '../../../interfaces/IEmployeeResponse';
import DialogBox from '../../Common/DialogBox';
import DateSelector from '../../Common/FormFields/DateSelector/DateSelector';
import EmailAutocomplete from '../../Common/FormFields/EmailAutocomplete/EmailAutocomplete';
import NumberInput from '../../Common/FormFields/NumberInput/NumberInput';
import ProjectCodeAutocomplete from '../../Common/FormFields/ProjectCodeAutocomplete';
import SelectInput from '../../Common/FormFields/SelectInput/SelectInput';
import { AccountStore, CodaEmployeesStore, CodaProjectsStore, DealOriginStore, RrpStore } from '../../../stores';

const GlobalStyle = createGlobalStyle`${newRequestStyles}`;
const hoursMaxValue = 200;
const numberPattern = /^\d{0,4}(\.?\d{0,2})?$/i;

interface IFieldStates {
  dateValue?: string;
  dealOrigin?: string;
  dealOriginError?: boolean;
  email?: string;
  emailValidated?: string;
  emailError?: boolean;
  hours?: string;
  hoursError?: boolean;
  kickoffBrief?: string;
  name?: string;
  projectIdCalculated?: string;
  projectIdError?: boolean;
  projectMonthError?: boolean;
}

const resetFieldState = {
  dateValue: '',
  dealOrigin: '',
  dealOriginError: false,
  email: '',
  emailValidated: '',
  emailError: false,
  hours: '',
  hoursError: false,
  kickoffBrief: '',
  name: '',
  projectCode: '',
  projectIdCalculated: undefined,
  projectIdError: false,
  projectMonthError: false
};

interface IProps {
  accountStore: AccountStore;
  codaEmployeesStore: CodaEmployeesStore;
  codaProjectsStore: CodaProjectsStore;
  rrpStore: RrpStore;
  dealOriginStore: DealOriginStore;
}

interface IState {
  consultantId: string | undefined;
  currentUser: IEmployeeResponse | undefined;
  dateValue: string;
  dealOriginError: boolean;
  dealOrigin: string;
  email: string;
  emailValidated: string;
  emailError: boolean;
  employees: IEmployeeResponse[];
  fromValue: number;
  hours: string;
  hoursError: boolean;
  isLoading: boolean;
  kickoffBrief: string;
  name: string;
  projectCode: string;
  projectIdCalculated: string | undefined;
  projectIdError: boolean;
  projectMonthError: boolean;
  toValue: number;
  submitSuccess: boolean;
  submitError: string | undefined;
}

@inject('accountStore', 'codaEmployeesStore', 'codaProjectsStore', 'rrpStore', 'dealOriginStore')
@observer
class NewRequest extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      consultantId: undefined,
      currentUser: undefined,
      dateValue: '',
      dealOrigin: '',
      dealOriginError: false,
      email: '',
      emailValidated: '',
      emailError: false,
      employees: [],
      fromValue: 0,
      hours: '',
      hoursError: false,
      isLoading : false,
      kickoffBrief: '',
      name: '',
      projectCode: '',
      projectIdCalculated: undefined,
      projectIdError: false,
      projectMonthError: false,
      submitSuccess: false,
      submitError: undefined,
      toValue: 0,
    };
  }

  public componentDidMount() : void {
    this.setRequiredState();
  }

  public setRequiredState(): void {
    const { accountStore, codaEmployeesStore } = this.props;
    const { currentUser } = this.state;

    if (!currentUser) {
      const employees = codaEmployeesStore ? codaEmployeesStore.employeesList : [];
      const email = accountStore.currentUser && accountStore.currentUser.email ? accountStore.currentUser.email : 'undefined';
      const user = _.find(employees, { email });
      if (user) {
        this.setState({
          currentUser: user,
        });
      }
    }
  }

  private onFormSubmit = async (): Promise<void> => {
    const { dateValue, dealOrigin, emailValidated, hours, projectIdCalculated, projectMonthError, dealOriginError,
      projectIdError, emailError, hoursError, consultantId, currentUser, fromValue, name, projectCode, toValue,
      kickoffBrief } = this.state;
    const { rrpStore } = this.props;

    const states: IFieldStates[] = [
      { dateValue, projectMonthError },
      { dealOrigin, dealOriginError },
      { emailValidated, emailError },
      { hours, hoursError },
      { projectIdCalculated, projectIdError },
    ];

    let hasError = false;

    // reset error states
    states.forEach((state: IFieldStates) => {
      const errorKey = Object.keys(state)[1];
      this.setState((prevState) => ({
        ...prevState,
        [errorKey]: false,
      }));
    });

    // Error handling here
    states.forEach((state: IFieldStates) => {
      const stateKey = Object.keys(state)[0];
      const errorKey = Object.keys(state)[1];
      const value = state[stateKey];
      if (stateKey === 'hours') {
        if (!(numberPattern.test(value) && value > 0 && value <= hoursMaxValue)) {
          this.setErrorKeyTrue(errorKey);
          hasError = true;
        }
      } else if (!value || value === 0 || value === '') {
        this.setErrorKeyTrue(errorKey);
        hasError = true;
      }
    });

    if (!hasError && currentUser) {
      const ledgerData: IPostLedgerData = {
        consultantId: consultantId || -1,
        currentUser,
        dateValue,
        dealOrigin,
        email : emailValidated,
        fromValue,
        hours: parseFloat(hours),
        kickoffBrief,
        name,
        projectCode,
        toValue
      };
      try {
        this.setState({isLoading : true});
        await rrpStore.postLedger(ledgerData);
        this.toggleSuccessModal();
        this.clearForm();
      } catch (error) {
        this.setState({submitError : `Error while submitting request: ${error}`});
      }
      this.setState({isLoading : false});
    }
  };

  private setErrorKeyTrue(errorKey: string): void {
    this.setState((prevState) => ({
      ...prevState,
      [errorKey]: true,
    }));
  }

  private clearForm = (): void => {
    this.setState((prevState) => ({
      ...prevState,
      ...resetFieldState,
    }));
  };

  private onFormReset = (): void => {
    this.clearForm();
  };

  private handleSelectAndDateChange = (value: string, state: string): void => {
    this.setState((prevState) => ({
      ...prevState,
      [state]: value,
    }));
  };

  public handleGetFieldValue = (value: string, state: string): void => {
    const { codaEmployeesStore, codaProjectsStore } = this.props;
    if (state === 'email') {
      const employee = codaEmployeesStore.employeeNameEmail.find(employeeName => employeeName.email === value);
      if (employee) {
        this.setState((prevState) => ({
          ...prevState,
          consultantId: employee.id,
          [state]: employee.email,
          name: employee.name,
          emailValidated: employee.email
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          [state]: value,
          emailValidated: ''
        }));
      }

    } else if (state === 'projectCode') {
      const p = codaProjectsStore.projectCodes.find(project => project.code === value);
      if (p) {
        this.setState((prevState) => ({
          ...prevState,
          projectIdCalculated: p.id,
          [state]: value,
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          projectIdCalculated: undefined,
          [state]: value,
        }));
      }
    } else {
      this.setState((prevState) => ({
        ...prevState,
        [state]: value,
      }));
    }
  };

  private toggleSuccessModal = (): void => {
    this.setState((prevState) => ({ submitSuccess: !prevState.submitSuccess }));
  };

  private clearErrorModal = (): void => {
    this.setState({ submitError: undefined });
  };

  private handleInputCheck = (value: { fromValue: number; toValue: number }): void => {
    this.setState({
      fromValue: value.fromValue,
      toValue: value.toValue,
    });
  };

  private handleKickoffBriefChange = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    // assign to constant to prevent error https://reactjs.org/docs/events.html#event-pooling
    const thisEventValue = event.target.value;
    this.setState((prevState) => {
      return {
        ...prevState,
        kickoffBrief: thisEventValue
      };
    });
  };

  public render(): React.ReactNode {
    const { codaEmployeesStore, codaProjectsStore, dealOriginStore } = this.props;
    const { emailError, email, name, projectIdError, projectCode, dealOriginError, dealOrigin,
      projectMonthError, dateValue, hoursError, hours, submitSuccess, submitError, isLoading, kickoffBrief } = this.state;

    return (
      <div className="create-request paper-padding-small">
        <GlobalStyle />
        <Paper style={{ padding: '24px' }}>
          <div
            style={
              {
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                marginBottom: '48px',
                padding: '8px',
              }
            }
          >
            Create New Request
          </div>
          {
            isLoading? (
              <div className="spinner-div">
                <Spinner />
              </div>
            ) : null
          }
          <div>
            <div className="flex-row" style={{ marginBottom: '24px' }}>
              <div>
                <EmailAutocomplete
                  isError={emailError}
                  state="email"
                  handleReturnValue={this.handleGetFieldValue}
                  data={codaEmployeesStore.employeeNameEmailLabel}
                  name="consultantsEmails"
                  label="Consultant's Email"
                  isRequired
                  selectedValue={email}
                />
              </div>
              <div>
                <TextField
                  inputProps={
                    {
                      name: 'consultant-name',
                    }
                  }
                  value={name}
                  disabled
                  label="Consultant's Name"
                  fullWidth
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginBottom: '24px' }}>
              <div>
                <ProjectCodeAutocomplete
                  isError={projectIdError}
                  state="projectCode"
                  handleReturnValue={this.handleGetFieldValue}
                  data={codaProjectsStore.projectCodesLabel}
                  name="projectCode"
                  label="Project Code"
                  isRequired
                  selectedValue={projectCode}
                />
              </div>
              <div>
                <SelectInput
                  isError={dealOriginError}
                  orderByText
                  data={dealOriginStore.dealOrigins.map(origin => ({ displayText: origin, value: origin}))}
                  name="dealOrigin"
                  label="Deal Origin"
                  isRequired
                  state="dealOrigin"
                  handleReturnValue={this.handleGetFieldValue}
                  selectedValue={dealOrigin}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginBottom: '24px' }}>
              <div style={{ padding: '0px' }}>
                <DateSelector
                  isError={projectMonthError}
                  isRequired
                  name="projectMonth"
                  label="Project Month"
                  handleDateChange={this.handleSelectAndDateChange}
                  handleReturnInputCheck={this.handleInputCheck}
                  selectedValue={dateValue}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginBottom: '12px' }}>
              <div>
                <NumberInput
                  handleReturnValue={this.handleGetFieldValue}
                  name="hours"
                  state="hours"
                  label="Hours"
                  helperText="Please enter a value of 200 or less"
                  isRequired
                  isError={hoursError}
                  selectedValue={hours}
                  pattern={numberPattern}
                />
              </div>
            </div>
            <div className="flex-row" style={{ marginBottom: '24px' }}>
              <div>
                <TextField
                  inputProps={
                  {
                    name: 'kick-off-brief',
                  }
                  }
                  value={kickoffBrief}
                  onChange={this.handleKickoffBriefChange}
                  label="Kick off brief"
                  fullWidth
                />
              </div>
            </div>
            <div className="flex-row">
              <div />
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  className="button-secondary"
                  onClick={this.onFormReset}
                  style={{ marginRight: '16px' }}
                  variant="contained"
                >
                  Reset
                </Button>
                <Button className="button-primary" onClick={this.onFormSubmit} variant="contained">
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </Paper>
        <DialogBox
          open={submitSuccess}
          handleModalState={this.toggleSuccessModal}
          body="Your request has been successfully submitted!"
          title="New Request"
        />
        <DialogBox
          open={!!submitError}
          handleModalState={this.clearErrorModal}
          body={submitError || ''}
          title="Error"
        />
      </div>
    );
  }
}

export default NewRequest;
