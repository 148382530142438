import firebase from 'firebase/app';
import { observable } from 'mobx';
import IAccountDAO from './IAccountDAO';

export default class AccountDAO implements IAccountDAO {
  @observable
  public currentUser = '';

  public firebaseAuth: firebase.auth.Auth;

  constructor(fb: firebase.app.App) {
    this.firebaseAuth = fb.auth();
  }

  public async login(): Promise<firebase.auth.UserCredential> {
    const provider = new firebase.auth.GoogleAuthProvider();
    // Forces account selection even when one account is available.
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const user = await this.firebaseAuth.signInWithPopup(provider);
    return user;
  }
}
