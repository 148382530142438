/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Ajax from './Ajax';
import ResponseError from './ResponseError';
import Params from './Params';

export default class Api extends Ajax {
  public static instance: Api;

  public static getInstance() : Api {
    if (!Api.instance) {
      Api.instance = new Api();
    }

    return Api.instance;
  }

  public async request(func: any): Promise<Response>  {
    return new Promise((resolve, reject) => {
      func()
        .then((res : Response) => {
          if (!res.ok) {
            console.log('API response error would be thrown');
            reject(new ResponseError(res));
          } else {
            resolve(res);
          }
        })
        .catch((e : any) => {
          console.log('Unknown response error would be thrown');
          reject(e);
        });
    });
  }

  public async get(url: string, params?: Params, headers?: Params) : Promise<Response> {
    return this.request(() => super.get(url, params, headers));
  }

  public async post(url: string, body: any, params?: Params, headers?: Params) : Promise<Response> {
    return this.request(() => super.post(url, body, params, headers));
  }

  public async put(url: string, body: any, params?: Params, headers?: Params) : Promise<Response> {
    return this.request(() => super.put(url, body, params, headers));
  }

  public async delete(url: string, body: any, params?: Params, headers?: Params) : Promise<Response> {
    return this.request(() => super.delete(url, body, params, headers));
  }
}
