import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import React, { ChangeEvent, Component, ReactNode } from 'react';
import { createGlobalStyle } from 'styled-components';

import { AccountStore, CodaEmployeesStore, RrpStore, DealOriginStore } from '../../../stores';
import Rrp from '../../../stores/models/Rrp';
import { INameAndEmail } from '../../../interfaces/INameAndEmail';
import { IEmployeeResponse } from '../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../interfaces/IReportDataJson';
import SelectInput from '../../Common/FormFields/SelectInput/SelectInput';
import { ITextValue } from '../../../interfaces/ITextValue';
import SearchBox from '../../Common/SearchBox';
import reportsStyles from './Reports.styles';
import ConsultantsView from './Views/Consultants';
import DealOriginView from './Views/DealOrigin';
import DefaultView from './Views/Default';
import ProjectsView from './Views/Projects';

type ProjectCode = {
  displayText: string,
  value: string,
  dealOrigin: string
};

interface IProps {
  accountStore: AccountStore;
  codaEmployeesStore: CodaEmployeesStore;
  rrpStore: RrpStore;
  dealOriginStore: DealOriginStore;
}

interface IFilters {
  approvedBy?: number;
  consultant?: INameAndEmail;
  requestor?: INameAndEmail;
  dateProcessedString?: string;
  dealOrigin?: string;
  endDateString?: string;
  hours?: number;
  id?: string;
  location?: string;
  month?: number;
  projectCode?: string;
  requestedBy?: number;
  startDateString?: string;
  status?: string;
  year?: number;
}

interface IState {
  dealOriginError: boolean;
  dealOrigin: string;
  endDay: string;
  inputFilter: string;
  by_location: string;
  month: string;
  projectCode: string;
  startDay: string;
  totalDays: ITextValue[];
  viewByFilter: string;
  year: number;
  pageNumber: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
  prevChosenMonth: string;
  prevChosenYear: number;
}

const MONTHS = [
  { displayText: 'January', value: '1' },
  { displayText: 'February', value: '2' },
  { displayText: 'March', value: '3' },
  { displayText: 'April', value: '4' },
  { displayText: 'May', value: '5' },
  { displayText: 'June', value: '6' },
  { displayText: 'July', value: '7' },
  { displayText: 'August', value: '8' },
  { displayText: 'September', value: '9' },
  { displayText: 'October', value: '10' },
  { displayText: 'November', value: '11' },
  { displayText: 'December', value: '12' },
];

const CURRENT_YEAR = new Date().getFullYear();
const START_YEAR = 2020;

let yearIndex = START_YEAR;
const endYear = CURRENT_YEAR + 2;
const years: ITextValue[] = [];
while (yearIndex < endYear) {
  years.push({ displayText: yearIndex.toString(), value: yearIndex });
  yearIndex += 1;
}

const GlobalStyle = createGlobalStyle`${reportsStyles}`;

@inject('accountStore', 'codaEmployeesStore', 'rrpStore', 'dealOriginStore')
@observer
class Reports extends Component<IProps, IState> {

  private searchInputMin = 3;

  constructor(props: IProps) {
    super(props);
    this.state = {
      by_location: '',
      dealOriginError: false,
      dealOrigin: '',
      endDay: '',
      inputFilter: '',
      itemsPerPage: 10,
      month: '',
      pageNumber: 1,
      projectCode: '',
      startDay: '',
      totalDays: [],
      totalItems: 0,
      totalPages: 0,
      viewByFilter: 'all',
      year: CURRENT_YEAR,
      prevChosenMonth: '',
      prevChosenYear: -1
    };
  }

  public componentDidMount() : void {
    this.setDefaultMonthAndYear();
  }

  public setDefaultMonthAndYear = (): void => {
    const date = new Date();
    const getMonth = date.getMonth() + 1;
    const getYear = date.getFullYear();
    const totalDays = this.daysInMonth(getMonth, getYear);

    const totalDaysKeyValue: ITextValue[] = [];
    for (let i = 1; i <= totalDays; i += 1) {
      totalDaysKeyValue.push({ displayText: i.toString(), value: i });
    }

    this.setState(
      {
        month: String(getMonth),
        totalDays: totalDaysKeyValue,
        year: getYear
      },
      () => {
        this.getLedger();
      }
    );
  };

  public getLedger = (): void => {
    const { rrpStore } = this.props;
    const { month, year, prevChosenMonth, prevChosenYear } = this.state;
    const monthNum = parseFloat(month);
    const totalDaysInAMonth = new Date(Date.UTC(year, monthNum, 0)).getDate();
    const start_date = new Date(Date.UTC(year, monthNum - 1, 1));
    const end_date = new Date(Date.UTC(year, monthNum - 1, totalDaysInAMonth, 23, 59, 59, 999));

    if ((prevChosenMonth !== month) || (prevChosenYear !== year)) {
      this.setState(
        {
          prevChosenMonth: month,
          prevChosenYear: year,
        });
      rrpStore.fetchLedger(start_date, end_date);
    }
  };

  public searchBox = (): ReactNode => {
    const { inputFilter } = this.state;

    return (
      <SearchBox
        placeholder="Project Code or Consultant name"
        fullWidth
        onChange={this.inputSearchHandle}
        onClear={this.inputSearchClear}
        value={inputFilter}
      />
    );
  };

  public nextPage = (totalPages: number): void => {
    const { pageNumber } = this.state;
    if (pageNumber === totalPages) {
      return;
    }

    this.setState({ pageNumber: pageNumber + 1 });
  };

  public prevPage = (): void => {
    const { pageNumber } = this.state;
    if (pageNumber === 1) {
      return;
    }

    this.setState({ pageNumber: pageNumber - 1 });
  };

  private daysInMonth = (month: number, year: number): number => {
    return moment.utc([year, month], 'YYYY-MM').daysInMonth();
  };

  private handleSelectAndDateChange = (value: string, state: string): void => {
    this.setState(prevState => ({
      ...prevState,
      [state]: value,
      pageNumber: 1
    }));
  };

  private handleViewByFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      pageNumber: 1,
      viewByFilter: event.target.value,
    });
  };

  private handleFilterChange = (value: string, state: string): void => {
    this.setState(
      prevState =>
        (
          {
            ...prevState,
            [state]: value,
            pageNumber: 1
          }
        ),
      this.getLedger
    );
  };

  private inputSearchHandle = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    this.setState({
      inputFilter: event.currentTarget.value,
      pageNumber: 1
    });
  };

  private inputSearchClear = (): void => {
    this.setState({
      inputFilter: '',
      pageNumber: 1
    });
  };

  private resetFilter = (): void => {
    this.setDefaultMonthAndYear();
    this.setState({
      by_location: '',
      dealOrigin: '',
      endDay: '',
      inputFilter: '',
      projectCode: '',
      startDay: '',
      viewByFilter: 'all',
    });
  };

  public renderSelections(viewByFilter: string): ReactNode {
    return (
      <div className="flex-row paper-padding-large">
        <div className="sort">
          <span>View by:</span>
          <span className="filter-view">
            <Radio
              aria-label="All"
              checked={viewByFilter === 'all'}
              name="radio-button-demo"
              onChange={this.handleViewByFilter}
              value="all"
            />
            <span>All</span>
          </span>
          <span className="filter-view">
            <Radio
              aria-label="Projects"
              checked={viewByFilter === 'projects'}
              name="radio-button-demo"
              onChange={this.handleViewByFilter}
              value="projects"
            />
            <span>Projects</span>
          </span>
          <span className="filter-view">
            <Radio
              aria-label="Consultants"
              checked={viewByFilter === 'consultants'}
              name="radio-button-demo"
              onChange={this.handleViewByFilter}
              value="consultants"
            />
            <span>Consultants</span>
          </span>
          <span className="filter-view">
            <Radio
              aria-label="Deal Origin"
              checked={viewByFilter === 'dealOrigin'}
              name="radio-button-demo"
              onChange={this.handleViewByFilter}
              value="dealOrigin"
            />
            <span>Deal Origin</span>
          </span>
        </div>
      </div>
    );
  }


  public renderHeader(stateMonth: string, stateYear: number, totalDays: ITextValue[],
    startDay: string, endDay: string, dealOrigin: string, projectCodes : ProjectCode[],
    projectCode: string, uniqueLocations: ITextValue[], by_location: string): ReactNode {

    const { dealOriginStore } = this.props;
    const dealOrigins : ITextValue[] = dealOriginStore.dealOrigins.map(
      origin => ({ displayText: origin, value: origin}));

    return (
      <div className="header-padding">
        <Paper className="paper-padding-large">
          <div className="flex-row">
            <SelectInput
              isError={false}
              orderByText={false}
              data={MONTHS}
              name="month"
              label="Month"
              isRequired={false}
              state="month"
              handleReturnValue={this.handleFilterChange}
              selectedValue={stateMonth}
            />

            <SelectInput
              isError={false}
              orderByText
              data={years}
              name="year"
              label="Year"
              isRequired={false}
              state="year"
              handleReturnValue={this.handleFilterChange}
              selectedValue={stateYear}
            />

            <SelectInput
              isError={false}
              orderByText={false}
              data={totalDays}
              name="startDay"
              label="Start Day"
              isRequired={false}
              state="startDay"
              handleReturnValue={this.handleFilterChange}
              selectedValue={startDay}
            />

            <SelectInput
              isError={false}
              orderByText={false}
              data={totalDays}
              name="endDay"
              label="End Day"
              isRequired={false}
              state="endDay"
              handleReturnValue={this.handleFilterChange}
              selectedValue={endDay}
            />

            <SelectInput
              isError={false}
              orderByText
              data={dealOrigins}
              name="dealOrigin"
              label="Deal Origin"
              isRequired={false}
              state="dealOrigin"
              handleReturnValue={this.handleSelectAndDateChange}
              selectedValue={dealOrigin}
            />

            <SelectInput
              isError={false}
              orderByText
              data={projectCodes}
              name="projectCode"
              label="By Project"
              isRequired={false}
              state="projectCode"
              handleReturnValue={this.handleFilterChange}
              selectedValue={projectCode}
            />

            <SelectInput
              isError={false}
              orderByText
              data={uniqueLocations}
              name="byLocation"
              label="By Location"
              isRequired={false}
              state="by_location"
              handleReturnValue={this.handleSelectAndDateChange}
              selectedValue={by_location}
            />
            <Hidden only={['md', 'lg', 'xl']}>
              <div className="top-space-sm">
                {this.searchBox()}
              </div>
            </Hidden>
            <Button
              onClick={this.resetFilter}
              className="button-secondary"
              style={{ marginRight: '16px' }}
              variant="contained"
            >
              Reset
            </Button>
          </div>
          <Hidden only={['xs', 'sm']}>
            <div className="flex-row top-space-sm">
              <div>
                {this.searchBox()}
              </div>
              <div />
              <div />
            </div>
          </Hidden>
        </Paper>
      </div>
    );
  }

  public renderReportContent(viewByFilter: string, dealOrigin: string, projectCodes : ProjectCode[],
    projectCode: string, filteredApprovedRequest: IReportDataJson[], pageNumber: number,
    totalPages: number, employees: IEmployeeResponse[], renderView: IReportDataJson[]): ReactNode {

    const { dealOriginStore } = this.props;

    return (
      <div className="paper-padding">
        <div>
          {
            viewByFilter === 'all' ? (
              <DefaultView
                viewData={renderView}
                pageNumber={pageNumber}
                totalPages={totalPages}
                projectCode={projectCode}
                dealOrigin={dealOrigin}
                nextPage={() => this.nextPage(totalPages)}
                prevPage={this.prevPage}
                employeeList={employees}
              />
            ) : null
          }
          {
            viewByFilter === 'projects' ? (
              <ProjectsView
                employeeList={employees}
                filteredData={filteredApprovedRequest}
                projectCodes={projectCodes}
              />
            ) : null
          }
          {
            viewByFilter === 'dealOrigin' ? (
              <DealOriginView
                employeeList={employees}
                filteredData={filteredApprovedRequest}
                dealOriginList={dealOriginStore.dealOrigins}
              />
            ) : null
          }
          {
            viewByFilter === 'consultants' ? (
              <ConsultantsView
                employeeList={employees}
                filteredData={filteredApprovedRequest}
              />
            ) : null
          }
        </div>
      </div>
    );
  }

  public render(): ReactNode {
    const { itemsPerPage, pageNumber, inputFilter, month: stateMonth, year: stateYear, by_location, dealOrigin,
      projectCode, startDay, totalDays, endDay, viewByFilter } = this.state;
    const offset = (pageNumber - 1) * itemsPerPage;
    const { codaEmployeesStore, rrpStore } = this.props;
    const employees = codaEmployeesStore.employeesList;
    const secondStageFiltered: IReportDataJson[] = [];

    const locations = employees.map((employee) => {
      return { displayText: employee.location, value: employee.location };
    });
    let uniqueLocations = _.uniqBy(locations, 'value');
    uniqueLocations = _.orderBy(uniqueLocations, 'value');

    const filters: IFilters = {};
    const rqs = rrpStore.reportData.map((request: Rrp) => {
      const date = request.startDate;
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const consultant: IEmployeeResponse | undefined = employees.find(employee =>  employee.email === request.consultant.email);
      const location = consultant !== undefined ? consultant.location : '';

      const shorthand = {
        location,
        month,
        year
      };

      return {
        ...shorthand,
        approvedBy: request.approvedBy,
        consultant: request.consultant,
        dateProcessedString: request.getDateProcessedString(),
        dealOrigin: request.dealOrigin,
        endDateString: request.getEndDateString(),
        hours: request.hours,
        id: request.id,
        projectCode: request.projectCode,
        requestedBy: request.requestedBy,
        requestor: request.requestor,
        startDateString: request.getStartDateString(),
        status: request.status,
      };
    });

    const arrProjectCode = rqs.map((request) => {
      return { displayText: request.projectCode, value: request.projectCode, dealOrigin: request.dealOrigin };
    });

    const projectCodes = _.uniqBy(arrProjectCode, 'value');

    if (projectCode) {
      filters.projectCode = projectCode;
    }

    if (dealOrigin) {
      filters.dealOrigin = dealOrigin;
    }

    if (by_location) {
      filters.location = by_location;
    }

    if (startDay && endDay) {
      const startDate = new Date(Date.UTC(stateYear, parseFloat(stateMonth), parseFloat(startDay))).toISOString();
      const endDate = new Date(Date.UTC(stateYear, parseFloat(stateMonth), parseFloat(endDay))).toISOString();
      filters.startDateString = startDate;
      filters.endDateString = endDate;
    }

    // Note: filtering occurs in 2 stages:
    // first stage is where data is filtered according to values chosen in the dropdowns
    // second stage is by the searchbox's inputFilter
    const firstStageFiltered: IReportDataJson[] = _.filter(rqs, filters);

    for (let i = 0; i < firstStageFiltered.length; i+=1) {
      const request = firstStageFiltered[i];
      const regex = new RegExp(inputFilter, 'gi');
      const projectCodeResult = request.projectCode.match(regex);
      const consultantResult = request.consultant.name.match(regex);

      if (inputFilter && inputFilter.length >= this.searchInputMin) {
        if (projectCodeResult || consultantResult) {
          secondStageFiltered.push(request);
        }
      } else {
        secondStageFiltered.push(request);
      }
    }

    const totalPages = Math.ceil(secondStageFiltered.length / itemsPerPage);
    const renderView = rrpStore.getEntriesInView2(secondStageFiltered, offset, itemsPerPage);

    return (
      <div className="reports">
        <GlobalStyle />
        {this.renderSelections(viewByFilter)}
        {
          this.renderHeader(
            stateMonth,
            stateYear,
            totalDays,
            startDay,
            endDay,
            dealOrigin,
            projectCodes,
            projectCode,
            uniqueLocations,
            by_location
          )
        }
        {
          this.renderReportContent(
            viewByFilter,
            dealOrigin,
            projectCodes,
            projectCode,
            secondStageFiltered,
            pageNumber,
            totalPages,
            employees,
            renderView
          )
        }
      </div>
    );
  }
}

export default Reports;
