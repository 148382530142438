import classNames from 'classnames';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { inject, observer } from 'mobx-react';

import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import PrintIcon from '@material-ui/icons/Print';
import SettingsIcon from '@material-ui/icons/Settings';
import _ from 'lodash';

import { IRrpUser } from 'interfaces/IRrpUser';
import { CodaEmployeesStore, RrpStore, AccountStore, UserStore } from 'stores';
import styles from './Drawer.styles';
import Helpers from '../../../../utils/Helpers';
import { IEmployeesWithPermissions } from '../../../../interfaces/IEmployeesWithPermissions';

interface IProps extends RouteComponentProps {
  accountStore?: AccountStore;
  codaEmployeesStore?: CodaEmployeesStore;
  rrpStore?: RrpStore;
  userStore? : UserStore;
  openDrawer: (value: boolean) => void;
  openDrawerValue: boolean;
}

const GlobalStyle = createGlobalStyle`${styles}`;

@inject('accountStore', 'codaEmployeesStore', 'rrpStore', 'userStore')
@observer
class DrawerLayout extends React.Component<IProps> {

  public handleDrawerToggle = (): void => {
    const { openDrawer } = this.props;
    openDrawer(false);
  };

  private handleRouteChange = (url: string) => () => {
    const { history, openDrawer } = this.props;
    history.push(url);
    openDrawer(false);
  };

  private logout = (): void => {
    const { accountStore, history, openDrawer } = this.props;

    history.push('/login');
    openDrawer(false);
    accountStore?.logoutUser();
  };


  public render(): React.ReactNode {
    const { location, accountStore, codaEmployeesStore, rrpStore, userStore, openDrawerValue } = this.props;
    const pendingRequest = rrpStore?.pendingRequests;
    let avatar;
    let fullname;
    let user;

    const employees = codaEmployeesStore ? codaEmployeesStore.employeesList : [];
    const users: IRrpUser[] = userStore ? userStore.users : [];
    const currentUserEmail = accountStore && accountStore.currentUser ? accountStore.currentUser.email : '';
    const employeesWithPermissions: IEmployeesWithPermissions[] = Helpers.getEmployeesWithPermissions({ employees, users });
    const currentUserWithPermissions = _.find(employeesWithPermissions, ['email', currentUserEmail]);

    const pendingCount: number = pendingRequest ? pendingRequest.length : 0;
    const badge =
      pendingCount === 0 ? (
        <ListItemText
          className={
            classNames({
              'active-drawer-menu': location.pathname === '/approval-queue',
            })
          }
          primary="Approval Queue"
        />
      ) :
        (
          <Badge className="badge" badgeContent={pendingCount} color="secondary">
            <ListItemText
              className={
                classNames({
                  'active-drawer-menu': location.pathname === '/approval-queue',
                })
              }
              primary="Approval Queue"
            />
          </Badge>
        );

    if (accountStore?.currentUser && codaEmployeesStore) {
      user = _.find(codaEmployeesStore.employeesList, { email: accountStore.currentUser.email ? accountStore.currentUser.email : '' });
      const accStoreImage = accountStore.currentUser.photoURL ?? '';
      if (user) {
        fullname = `${user.given_name} ${user.family_name}`;
        avatar = accStoreImage ?? user.image_url;
      }
    }

    return (
      <div>
        <GlobalStyle />
        <div>
          <Drawer
            anchor="left"
            open={openDrawerValue}
            className="main-drawer"
            onClose={this.handleDrawerToggle}
            onKeyDown={this.handleDrawerToggle}
            classes={
              {
                paper: 'main-drawer-paper',
              }
            }
          >
            <div className="app-drawer-header">
              <div className="avatar-close-drawer-icon">
                <IconButton onClick={this.handleDrawerToggle}>
                  <ArrowBackIcon color="inherit" className="app-drawer-back-icon" />
                </IconButton>
              </div>
              <List className="avatar-ui-container">
                <ListItem className="avatar-img-container">
                  <ListItemIcon>
                    <Avatar src={avatar} alt="Avatar Img" className="avatar-img" />
                  </ListItemIcon>
                  <ListItemText className="avatar-img-details">
                    <div className="avatar-name">{fullname}</div>
                  </ListItemText>
                </ListItem>
              </List>
              <div
                className="avatar-logout-link"
                onClick={this.logout}
                onKeyDown={this.logout}
                role="button"
                tabIndex={0}
              >
                Logout
              </div>
            </div>
            {
              currentUserWithPermissions
                ? Helpers.canViewPage(currentUserWithPermissions, '/new-request') && (
                  <MenuItem onClick={this.handleRouteChange('/new-request')}>
                    <ListItemIcon>
                      <MoveToInboxIcon
                        className={
                          classNames({
                            'active-drawer-menu': location.pathname === '/new-request',
                          })
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        classNames({
                          'active-drawer-menu': location.pathname === '/new-request',
                        })
                      }
                      primary="New Request"
                    />
                  </MenuItem>
                )
                : null
            }
            {
              currentUserWithPermissions
                ? Helpers.canViewPage(currentUserWithPermissions, '/approval-queue') && (
                  <MenuItem onClick={this.handleRouteChange('/approval-queue')}>
                    <ListItemIcon>
                      <AssignmentTurnedInIcon
                        className={
                          classNames({
                            'active-drawer-menu': location.pathname === '/approval-queue',
                          })
                        }
                      />
                    </ListItemIcon>
                    {badge}
                  </MenuItem>
                )
                : null
            }
            {
              currentUserWithPermissions
                ? Helpers.canViewPage(currentUserWithPermissions, '/reports') && (
                  <MenuItem onClick={this.handleRouteChange('/reports')}>
                    <ListItemIcon>
                      <PrintIcon
                        className={
                          classNames({
                            'active-drawer-menu': location.pathname === '/reports',
                          })
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        classNames({
                          'active-drawer-menu': location.pathname === '/reports',
                        })
                      }
                      primary="Reports"
                    />
                  </MenuItem>
                )
                : null
            }
            {
              currentUserWithPermissions
                ? Helpers.canViewPage(currentUserWithPermissions, '/settings') && (
                  <MenuItem onClick={this.handleRouteChange('/settings')}>
                    <ListItemIcon>
                      <SettingsIcon
                        className={
                          classNames({
                            'active-drawer-menu': location.pathname === '/settings',
                          })
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      className={
                        classNames({
                          'active-drawer-menu': location.pathname === '/settings',
                        })
                      }
                      primary="Settings"
                    />
                  </MenuItem>
                )
                : null
            }
          </Drawer>
        </div>
      </div>
    );
  }
}

export default withRouter(DrawerLayout);
