import { css } from 'styled-components'; 

const styles = css`
  .login-div {
    margin: auto;
    text-align: center;
    padding-top: 100px;
  }
`;

export default styles;
