import { css } from 'styled-components'; 

const styles = css`
  .avatar-hour-list {
    font-size: 20px;
    line-height: 24px;
    color: #000;
  }

  .avatar-name-list {
    font-size: 16px;
    line-height: 24px;
    color: #000;
  }

  .avatar-email-list,
  .avatar-deal-origin-list {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5438);
  }
`;

export default styles;
