import Downshift from 'downshift';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

import projectCodeAutocompleteStyles from './ProjectCodeAutocomplete.styles';

const GlobalStyle = createGlobalStyle`${projectCodeAutocompleteStyles}`;

type InputProps = {
  id: string; 
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

interface IState {
  inputValue: string;
}

interface IProps {
  data: string[];
  isError?: boolean;
  isRequired?: boolean;
  name: string;
  label: string;
  state: string;
  selectedValue?: string;
  handleReturnValue: (value: string, state: string) => void;
}

class ProjectCodeAutocomplete extends React.Component<IProps, IState> {
  public styles = {
    paperCon: {
      position: 'absolute',
    },
    parentCon: {
      left: 0,
      position: 'relative',
      right: 0,
      zIndex: 1,
    },
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      inputValue: '',
    };
  }

  public static getDerivedStateFromProps(props : IProps, state: IState) : IState | null {
    const { selectedValue } = props;
    const { inputValue } = state;
    if (selectedValue !== inputValue) {
      return {
        inputValue: selectedValue || ''
      };
    }
    return null;
  }

  private renderInput = (inputPropsVar: {inputProps : InputProps, fullWidth: boolean, label: string} ): 
  React.ReactElement => {
    const { inputProps, fullWidth, label } = inputPropsVar;
    const { isRequired, isError } = this.props;
    return (
      <TextField
        required={isRequired}
        error={isError}
        inputProps={inputProps}
        fullWidth={fullWidth}
        label={label}
      />
    );
  };

  // Note: couldn't get rid of some of the 'any' types for the params, as they're coming from Downshift
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private renderSuggestion = (suggestion: string, index: number, itemProps: any, highlightedIndex: number | null,
    selectedItem: string | null): React.ReactElement => {

    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion}
        selected={isHighlighted}
        style={
          {
            fontSize: '14px',
            fontWeight: isSelected ? 500 : 400,
          }
        }
      >
        {suggestion}
      </MenuItem>
    );
  };

  private getSuggestions = (inputValue: string | null): string[] => {
    let count = 0;
    const { data } = this.props;
    const inputValueNotNull = inputValue || '';

    return data.filter(suggestion => {
      const keep =
        (!inputValueNotNull || suggestion.toLowerCase().indexOf(inputValueNotNull.toLowerCase()) !== -1) && count < 5;

      if (keep) {
        count += 1;
      }

      return keep;
    });
  };

  private handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.handleCodeOnStrings(event.target.value);
  };

  private handleSelectItem = (value: string | null): void => {
    this.handleCodeOnStrings(value);
  };

  private handleCodeOnStrings = (text: string | null): void => {
    const { handleReturnValue, state } = this.props;
    const nonEmptyText = text || ''; 
    this.setState({
      inputValue: nonEmptyText,
    });
    const code = nonEmptyText.split('|');
    if (code != null) {
      this.setState({
        inputValue: code[0].trim()
      });
      handleReturnValue(code[0].trim(), state);
    }
  };

  public render(): React.ReactNode {
    const { inputValue: valueState } = this.state;
    const { name, label } = this.props;
    return (
      <div>
        <GlobalStyle />
        <Downshift inputValue={valueState} onSelect={this.handleSelectItem}>
          {
            ({ getInputProps, getItemProps, isOpen, inputValue, selectedItem, highlightedIndex }) => (
              <div className="parent-container">
                {
                  this.renderInput({
                    inputProps: getInputProps<InputProps>({
                      id: name,
                      onChange: this.handleInputChange,
                    }),
                    fullWidth: true,
                    label,
                  })
                }
                {
                  isOpen ? (
                    <Paper square className="paper-container">
                      {
                        this.getSuggestions(inputValue).map((suggestion, index: number) => {
                          const itemProps = getItemProps({
                            item: suggestion,
                          });
                          return this.renderSuggestion(
                            suggestion,
                            index,
                            itemProps,
                            highlightedIndex,
                            selectedItem,
                          );
                        }
                        )
                      }
                    </Paper>
                  ) : null
                }
              </div>
            )
          }
        </Downshift>
      </div>
    );
  }
}

export default ProjectCodeAutocomplete;
