import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import * as React from 'react';

import { RrpStore } from '../../../../stores';
import PendingRequestsTables from '../Blocks/PendingRequestsTables';
import { IRrpMonth } from '../../../../interfaces/IRrpMonth';

interface IProps {
  rrpStore: RrpStore;
}

@inject('rrpStore')
@observer
class PendingRequests extends React.Component<IProps> {

  public render(): React.ReactNode {
    const { rrpStore } = this.props;
    const { pendingRequests } = rrpStore;

    const yearmonths = pendingRequests.map(request => moment.utc(request.startDate).format('YYYY-MM'));
    // get unique months
    const uniqByMonth = _.uniq(yearmonths);
    const orderedByMonth = _.orderBy(uniqByMonth, 'desc');
    const viewData: IRrpMonth[] = orderedByMonth.map((yearMonth) => {
      const entries = _.filter(pendingRequests, (rrp) => {
        const rrpMonth = moment.utc(rrp.startDate).format('YYYY-MM');
        return rrpMonth === yearMonth;
      });
      return {
        data: entries,
        month: yearMonth
      };
    });

    return viewData.length !== 0 ? (
      <div className="approval-queue">
        <div className="table-title">
          Approval Queue
        </div>
        <div className="tables">
          {
            viewData.map((tableData: IRrpMonth) => {
              return <PendingRequestsTables key={tableData.month} tableData={tableData} rrpStore={rrpStore} />;
            })
          }
        </div>
      </div>
    ) : null;
  }
}

export default PendingRequests;
