export const PermissionKeys = ['can-create-request', 'can-view-approve', 'can-manage-approve', 
  'can-view-reports', 'can-change-settings'] as const;

export const PermissionLabels: Record<string, string> = {
  'can-create-request': 'Can Create Request', 
  'can-view-approve': 'Can View Approve',
  'can-manage-approve': 'Can Manage Approve',
  'can-view-reports': 'Can View Reports',
  'can-change-settings': 'Can Change Settings'
} as const;
