import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as React from 'react';

interface IProps {
  open: boolean;
  title?: string;
  body: string;
  handleModalState: () => void;
}

const DialogBox: React.FunctionComponent<IProps> = (props) => {
  const { handleModalState, open, title, body } = props;
  return (
    <Dialog
      open={open}
      onClose={handleModalState}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title ? <DialogTitle id="alert-dialog-title">{title}</DialogTitle> : null}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleModalState} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DialogBox.defaultProps = {
  title: 'Default'
};

export default DialogBox;
