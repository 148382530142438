import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import ListIdentity from '../../../../Common/ListIdentity';
import styles from '../BlocksCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;


interface IProps {
  data: IReportDataJson;
  employeeList: IEmployeeResponse[];
}

const ApprovedRRP: React.FC<IProps> = (props) => {
  const { data, employeeList } = props;
  const { consultant, dealOrigin, hours, requestor, projectCode } = data;
  const user = employeeList.find(employee => employee.email === requestor?.email);
  const consultantInfo = employeeList.find(employee => employee.email === consultant.email.trim());
  const requestedByUser = user ? `${user.given_name} ${user.family_name}` : '';
  const consultantName = consultantInfo ? `${consultantInfo.given_name} ${consultantInfo.family_name}` : '';

  return (
    <>
      <GlobalStyle />
      <div className="list-container">
        <div>
          <ListIdentity
            name={consultantName}
            email={consultant.email}
          />
        </div>
        <div className="center-contents avatar-deal-origin-list project-code">{projectCode}</div>
        <div className="center-contents avatar-deal-origin-list deal-origin">{dealOrigin}</div>
        <div className="center-contents requested-by">{requestedByUser}</div>
        <div className="center-contents allocation">
          {hours}
          {' '}
          Hours
        </div>
      </div>
    </>
  );
};

export default ApprovedRRP;
