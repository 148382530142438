export const USERS_COLLECTION = 'users';

export const LEDGER_COLLECTION = 'ledger';

export const APP_SETTINGS_COLLECTION = 'appSettings';

export const ACTIVITY_LOG_COLLECTION = 'activityLog';

export const DEAL_ORIGINS_COLLECTION = 'dealOrigins';

export const APP_SETTINGS_NODE_ID = 'WOvMpFvoKLMpFJX3jZpo';

export const MONTHS_OF_YEAR = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];