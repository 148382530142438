import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import firebase from 'firebase/app';

import { IRrpUser } from 'interfaces/IRrpUser';
import { USERS_COLLECTION } from '../../constants/constants';

export default class UserStore {
  private firebaseApp: firebase.app.App;

  @observable
  public users : IRrpUser[] = [];

  public static createStore(firebaseApp: firebase.app.App): UserStore {
    return new UserStore(firebaseApp);
  }

  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    makeObservable(this);
  }    

  public async createOrUpdateUser(data: IRrpUser) : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const docUsers = fs.collection(USERS_COLLECTION);

    docUsers.doc(data.email).set(data);
  }

  @action('UserStore:fetchUsers')
  public async fetchUsers() : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const docUsers = fs.collection(USERS_COLLECTION);
    const snapshot = await docUsers.get();
    const usersDocs: IRrpUser[] = [];
    snapshot.forEach((doc) => {
      const data : IRrpUser = doc.data() as IRrpUser;
      usersDocs.push(data);
    });
    runInAction(() => {
      this.users = usersDocs;
    });
  }
}
