import { css } from 'styled-components';

const newRequestStyles = css`
  .spinner-div {
    height: 2.6em;
    padding: 20px;
  }

  @media screen and (max-width: 730px) {
    .create-request {
      .flex-row {
        display: block;
        margin-bottom: 0px !important;
      }
      .date-selection-checkbox-container {
        margin-top: 16px;
      }
      .date-selection-checkbox-container > div {
        padding: 0px;
      }
    }
  }
`;

export default newRequestStyles;
