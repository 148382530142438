import AppBar from '@material-ui/core/AppBar';
import styledComponents, { css } from 'styled-components';

const appStyles = css`
  * {
    font-family: 'Lato', sans-serif !important;
  }
  body {
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-font-smoothing: antialiased;
  }

  .hamburger-icon {
    margin-left: -12px !important;
    margin-right: 20px !important;
  }

  .app-bar-title {
    cursor: pointer;
  }

  /* -------------------------------------------------------------------
  ----------- Styles common to multiple components ---------------------
  -------------------------------------------------------------------- */

  .MuiAppBar-positionFixed {
    /* This is used for header styling */
    position: inherit !important;
    margin-bottom: 10px !important;
  }

  .error-text {
    color: red;
    font-weight: bold;
    padding: 10px;
  }

  .spinner-full-page {
    padding: 40px;
  }

  .list-container {
    > div:first-child {
      width: 350px;
      flex: none;
    }
  }

  .paper-padding-small {
    padding: 16px;
    max-width: 900px;
    margin: auto;
  }

  .paper-padding {
    padding: 16px;
    max-width: 1000px;
    margin: auto;
  }

  .paper-padding-large {
    padding: 16px;
    max-width: 1200px;
    margin: auto;
  }

  .top-space-sm {
    margin-top: 16px;
  }

  .top-space-md {
    margin-top: 32px;
  }

  .clear-field {
    cursor: pointer;
    margin-left: 0px !important;
  }

  .table-title {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    margin-bottom: 0px;
    padding: 8px 8px 12px 0px;
  }

  .flex-row {
    display: flex;
    flex-flow: row wrap;
  }

  .flex-row > div {
    flex: 1;
    padding-left: 8px;
    padding-right: 8px;
  }

  .flex-row-table {
    position: relative;
    display: block;
  }

  .flex-row-table .flex-row-table-header {
    flex: 1;
    display: flex;
    flex-flow: row wrap;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }

  .flex-row-table .flex-row-table-header > div {
    flex: 1;
    padding: 5px;
  }

  .flex-row-table .flex-row-table-header .last-column {
    width: 150px;
    flex: none;
  }

  .flex-row-table .flex-row-table-body {
    border: 1px solid #979797;
  }

  .flex-row-table .flex-row-table-header > .first-header-column-no-flex {
    width: 350px !important;
    flex: 0 0 auto;
  }

  .flex-row-table .flex-row-table-footer {
    display: flex;
    flex-flow: row;
    display: flex;
    justify-content: flex-end;
  }

  .result-tables {
    margin-bottom: 40px;
  }

  .table-container {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-flow: row wrap;
  }

  .center-contents {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
  }

  .avatar-deal-origin-list {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5438);
  }

  .avatar-email-list,
  .avatar-deal-origin-list {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5438);
  }

  .date-selection-checkbox-container {
    margin-top: 16px;
  }

  .list-container {
    border-bottom: 1px solid #979797;
    padding: 16px;
    display: flex;
    flex-flow: row wrap;
    > div:first-child {
      width: 350px;
      flex: none;
    }
  }

  .list-container > div {
    flex: 1;
  }

  .fm-green {
    color: #4cb57c !important;
  }

  .button-primary {
    background-color: #4cb57c !important;
    border-color: #4cb57c !important;
    color: #fff !important;
  }

  .button-secondary {
    background-color: #fff !important;
    color: #4cb57c !important;
    border-color: #4cb57c !important;
  }
`;

export const Header = styledComponents(AppBar)`
  background-color: #E0E0E0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
`;

export default appStyles;
