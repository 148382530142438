import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import styles from '../BlocksCommon.styles';
import ListIdentity from '../../../../Common/ListIdentity';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IProps {
  employeeList: IEmployeeResponse[];
  data: IReportDataJson;
}

const ProjectsViewRows: React.FC<IProps> = (props) => {
  const { data, employeeList } = props;
  const { consultant, hours, requestedBy, requestor } = data;
  const user = employeeList.find(employee => employee.id === requestedBy);
  const consultantInfo = employeeList.find(employee => employee.email === consultant.email.trim());
  const requestedByUser = user ? `${user.given_name} ${user.family_name}` : requestor?.name;
  const consultantName = consultantInfo ? `${consultantInfo.given_name} ${consultantInfo.family_name}` : '';

  return (
    <>
      <GlobalStyle />
      <div className="list-container">
        <div>
          <ListIdentity
            name={consultantName}
            email={consultant.email}
          />
        </div>
        <div className="center-contents">{requestedByUser}</div>
        <div className="center-contents">
          {hours}
          {' '}
          Hours
        </div>
      </div>
    </>
  );
};

export default ProjectsViewRows;
