import { css } from 'styled-components'; 

const paginationStyles = css`
  .flex-footer-nav {
    padding: 10px 0px 5px;
    display: flex;
    align-items: center;
  }

  .flex-footer-nav > .navigation button:first-child, .flex-footer-nav > .indicator {
    margin-right: 15px;
  }
`;

export default paginationStyles;
