export default class Params {
  public dict: Map<string, string>;

  constructor(obj: Params | undefined) {
    this.dict = new Map();

    if (!obj) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      this.set(key, obj[key]);
    });
  }

  public get size() : number {
    return this.dict.size;
  }

  public forEach(callback: (key: string, value: string) => void) : void {
    this.dict.forEach((value, key) => callback(key, value));
  }

  public map(callback: (key: string, value: string) => string) : string[] {
    const result: string[] = [];
    this.dict.forEach((value, key) => {
      result.push(callback(key, value));
    });

    return result;
  }

  public objectLiteral() : string[][] {
    const result: string[][] = [];
    this.dict.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  }

  public set(key: string, value: string) : void {
    this.dict.set(key, value);
  }
}
