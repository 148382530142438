import React from 'react';
import { createGlobalStyle } from 'styled-components';
import spinnerStyle from './Spinner.styles';

const GlobalStyle = createGlobalStyle`${spinnerStyle}`;
 
const Spinner: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <div className="spinner-body">
        <div className="loading-spinner" />
      </div>
    </>
  );
};
 
export default Spinner;