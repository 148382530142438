import { css } from 'styled-components'; 

const styles = css`
  @media screen and (max-width: 991px) {
    .flex-row-table-header {
      display: none !important;
    }
  }  
`;

export default styles;
