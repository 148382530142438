import { Coda } from 'coda-js';
import { IRrpCodaField } from 'interfaces/IRrp';

const apiToken = process.env.REACT_APP_CODA_API_TOKEN ? process.env.REACT_APP_CODA_API_TOKEN : '';

// Doc: RRP Data
const docId = 'lDhl33hv_D';

// Table: RRP Ledger in Page: RRP Base Data
const tableId = 'grid-ph6BtUU-gj';

const coda = new Coda(apiToken);

export default class CodaRRPLogMasterStore {

  public static createStore(): CodaRRPLogMasterStore {
    return new CodaRRPLogMasterStore();
  }

  public async create(rrp: IRrpCodaField): Promise<void> {
    const table = await coda.getTable(docId, tableId);

    await table.insertRows([
      rrp
    ]);
  }

  // Not used, existing Airtable method converted to Coda
  public async delete(id: string[]): Promise<void> {
    const table = await coda.getTable(docId, tableId);

    await table.deleteRows([...id]);
  }

}