import { inject, observer } from 'mobx-react';
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

import DialogBox from 'components/Common/DialogBox';
import { IActivityLog } from 'interfaces/IActivityLog';
import { ACTIVITY_LOG_COLLECTION } from 'constants/constants';
import { ILedgerData } from 'interfaces/ILedgerData';
import { IEmployeeResponse } from 'interfaces/IEmployeeResponse';
import {
  AccountStore,
  CodaEmployeesStore,
  CodaProjectsStore,
  CodaRRPLogMasterStore,
  RrpStore,
  FirebaseStore,
  UserStore
} from '../../../../../stores';
import Rrp from '../../../../../stores/models/Rrp';
import { IRrpUser } from '../../../../../interfaces/IRrpUser';
import Helpers from '../../../../../utils/Helpers';
import approvalQueueStyles from './ApprovalQueue.styles';
import ListIdentity from '../../../../Common/ListIdentity';
import { IRrpCodaField } from '../../../../../interfaces/IRrp';

const GlobalStyle = createGlobalStyle`${approvalQueueStyles}`;

type RrpStatus = 'Approve'|'Reject';

interface IState {
  open: boolean;
  statusText: string;
  rrpStatus: string;
  submitError: string | undefined;
}

interface IProps {
  data: Rrp;
  accountStore?: AccountStore;
  codaEmployeesStore?: CodaEmployeesStore;
  codaProjectsStore?: CodaProjectsStore;
  codaRRPLogMasterStore?: CodaRRPLogMasterStore;
  rrpStore?: RrpStore;
  firebaseStore? : FirebaseStore;
  userStore? : UserStore;
}

@inject(
  'accountStore',
  'codaEmployeesStore',
  'codaProjectsStore',
  'codaRRPLogMasterStore',
  'rrpStore',
  'firebaseStore',
  'userStore'
)
@observer
class ApprovalQueueList extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      open: false,
      rrpStatus: '',
      statusText: '',
      submitError: undefined
    };

    const { data, rrpStore } = props;

    if (rrpStore) {
      rrpStore.fetchLedger(data.startDate, data.endDate);
    }
  }

  private handleClickOpen = (rrpStatus: RrpStatus): void => {
    const status = rrpStatus === 'Approve' ? 'Approved' : 'Rejected';
    this.setState({
      open: true,
      rrpStatus: status,
      statusText: rrpStatus,
    });
  };

  private handleClose = (): void => {
    this.setState({ open: false });
  };

  private clearErrorModal = (): void => {
    this.setState({ submitError: undefined });
  };

  private processRRP = async ():  Promise<void> => {
    this.handleClose();
    const {
      codaEmployeesStore,
      codaProjectsStore,
      codaRRPLogMasterStore,
      rrpStore,
      accountStore,
      firebaseStore,
      data
    } = this.props;
    const { rrpStatus } = this.state;

    if (firebaseStore && accountStore && accountStore.currentUser && codaEmployeesStore && codaProjectsStore && codaRRPLogMasterStore) {
      const employees = codaEmployeesStore.employeesList;
      const fs = firebaseStore.firebase.firestore();
      const logRef = fs.collection(ACTIVITY_LOG_COLLECTION);
      const email = accountStore.currentUser.email ?? 'undefined';
      const user = employees.find(employee =>  employee.email === email);

      const foundProject = codaProjectsStore.projectsList.find( project => project.code === data.projectCode );
      const billingStatus = foundProject ? foundProject.billingStatus : '';

      if (user && rrpStore) {
        const updateData : ILedgerData = {
          id: data.id,
          status : rrpStatus.toLowerCase(),
          dateProcessed : new Date(),
          approvedBy : user.id,
          approver : {
            email: user.email,
            name: `${user.given_name} ${user.family_name}`,
          }
        };

        const logData : IActivityLog = {
          ledgerId: data.id,
          email: data.consultant.email,
          dateCreated: new Date(),
          startDate: data.startDate,
          hours: data.hours,
          projectCode: data.projectCode,
          dealOrigin: data.dealOrigin,
          emailSent: false
        };

        const rrpData: IRrpCodaField = {
          'Consultant Email': data.consultant.email,
          'Project Code': data.projectCode,
          'Hours': data.hours,
          'Date Processed': new Date(),
          'Deal Origin': data.dealOrigin,
          'Approver Email': updateData.approver.email,
          'Approver Name': updateData.approver.name,
          'Approved': rrpStatus === 'Approved',
          'Requestor Email': data.requestor.email,
          'Requestor Name': data.requestor.name,
          'Payroll Year Month': Helpers.getYearMonth(data.startDate),
          'Billing Status': billingStatus
        };

        if (rrpData['Project Code'] === '') {
          const error = 'Project code empty';
          this.setState({submitError : `Error : ${error}`});
          return;
        }

        if (rrpData['Billing Status'] === '') {
          const error = 'Billing status empty for ';
          this.setState({submitError : `Error : ${error} ${rrpData['Project Code']}`});
          return;
        }

        if (rrpStatus === 'Approved') {
          try {
            await codaRRPLogMasterStore.create(rrpData);
          } catch (error) {
            this.setState({submitError : `Error while performing action: ${error}`});
          }
        }

        try {
          await rrpStore.updateLedger(updateData);
          await logRef.doc(data.id).set(logData);

          rrpStore.updateRrp(updateData);
          rrpStore.fetchLedger(data.startDate, data.endDate);
        } catch (error) {
          this.setState({submitError : `Error while performing action: ${error}`});
        }
      }
    }
  };

  private calculateApprovedHours = (): number => {
    const { data, rrpStore, codaEmployeesStore } = this.props;
    const employees = codaEmployeesStore ? codaEmployeesStore.employeesList : [];

    let approvedHours = 0;

    if (rrpStore) {
      const rrpArray = rrpStore.reportData.filter((request: Rrp) => data.consultant.email === request.consultant.email);

      const reportDataArray = rrpArray.map((request: Rrp) => {
        const date = request.startDate;
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const employeeResponse: IEmployeeResponse | undefined = employees.find(employee =>  employee.email === request.consultant.email);
        const location = employeeResponse !== undefined ? employeeResponse.location : '';

        const shorthand = {
          location,
          month,
          year
        };

        return {
          ...shorthand,
          approvedBy: request.approvedBy,
          consultant: request.consultant,
          dateProcessedString: request.getDateProcessedString(),
          dealOrigin: request.dealOrigin,
          endDateString: request.getEndDateString(),
          hours: request.hours,
          id: request.id,
          projectCode: request.projectCode,
          requestedBy: request.requestedBy,
          requestor: request.requestor,
          startDateString: request.getStartDateString(),
          status: request.status,
        };
      });

      approvedHours = rrpStore.getTotalHours(reportDataArray);
    }
    return approvedHours;
  };

  public render(): React.ReactNode {
    const { data, codaEmployeesStore, userStore, accountStore } = this.props;
    const { consultant, dealOrigin, hours, projectCode, requestedBy } = data;
    const { statusText, open, submitError } = this.state;
    const employees = codaEmployeesStore ? codaEmployeesStore.employeesList : [];
    const user = employees.find(employee => employee.id === requestedBy);
    const requestedByUser = user ? `${user.given_name} ${user.family_name}` : '';

    const users: IRrpUser[] = userStore ? userStore.users : [];
    const currentUserEmail = accountStore && accountStore.currentUser ? accountStore.currentUser.email : '';
    const employeesWithPermissions = Helpers.getEmployeesWithPermissions({ employees, users });
    const currentUserWithPermissions = employeesWithPermissions.find(employee => employee.email === currentUserEmail);

    const approvedHours = this.calculateApprovedHours();

    return (
      <div className="list-container">
        <GlobalStyle />
        <div>
          <ListIdentity
            name={consultant.name}
            email={consultant.email}
            dealOrigin={dealOrigin}
          />
        </div>
        <div className="center-contents">
          <div className="allocation">
            <div style={{ fontSize: '16px', lineHeight: '24px' }}>
              <span style={{ fontWeight: 'bold' }}>
                {hours}
                {' '}
                hours
              </span>
              {' '}
              on
            </div>
            <div>{projectCode}</div>
          </div>
        </div>
        <div className="center-contents requested-by">{requestedByUser}</div>
        <div className="center-contents buttons" style={{ width: '150px', flex: 'none' }}>
          {
            currentUserWithPermissions ? Helpers.canManageApprove(currentUserWithPermissions) &&
              (
                <div>
                  <Button
                    color="primary"
                    onClick={() => this.handleClickOpen('Approve')}
                    style={
                      {
                        backgroundColor: '#4cb57c',
                        height: '50px',
                        marginRight: '16px',
                        minWidth: '50px',
                        width: '50px',
                      }
                    }
                    variant="contained"
                  >
                    <ThumbUpIcon
                      style={
                        {
                          color: '#eae6e6',
                          left: '50%',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translate(-50%,-50%)',
                        }
                      }
                    />
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => this.handleClickOpen('Reject')}
                    style={{ backgroundColor: '#8a8a8a', height: '50px', minWidth: '50px', width: '50px' }}
                    variant="contained"
                  >
                    <ThumbDownIcon
                      style={
                        {
                          color: '#eae6e6',
                          left: '50%',
                          position: 'absolute',
                          top: '50%',
                          transform: 'translate(-50%,-50%)',
                        }
                      }
                    />
                  </Button>
                  <Dialog
                    open={open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {statusText}
                      {' '}
                      <span style={{ fontWeight: 'bold' }}>
                        {hours}
                        {' '}
                        hours
                      </span>
                      {' '}
                      on
                      {' '}
                      {projectCode}
                      ?
                    </DialogTitle>
                    <DialogContent>
                      <div>
                        <div className="avatar-hour-list">
                          Approved hours:
                          {' '}
                          <span style={{ fontWeight: 'bold' }}>
                            {approvedHours}
                          </span>
                        </div>
                        <div className="avatar-name-list">{consultant.name}</div>
                        <div className="avatar-email-list">{consultant.email}</div>
                        <div className="avatar-deal-origin-list">
                          FM
                          {dealOrigin}
                        </div>
                      </div>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.handleClose} color="primary">
                        Cancel
                      </Button>
                      <Button onClick={this.processRRP} color="primary" autoFocus>
                        {statusText}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              ) : null
          }
          <DialogBox
            open={!!submitError}
            handleModalState={this.clearErrorModal}
            body={submitError || ''}
            title="Error"
          />
        </div>
      </div>
    );
  }
}

export default ApprovalQueueList;
