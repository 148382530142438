import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Paper from '@material-ui/core/Paper';

import { RrpStore } from '../../../../stores';
import Rrp from '../../../../stores/models/Rrp';
import Pagination from '../../../Common/Pagination';
import RecentActivityList from '../Blocks/RecentActivityList';

interface IProps {
  rrpStore: RrpStore;
}

interface IRecentActivitiesState {
  pageNumber: number;
  itemsPerPage: number;
}

@inject('rrpStore')
@observer
class RecentActivities extends React.Component<IProps, IRecentActivitiesState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      itemsPerPage: 10,
      pageNumber: 1,
    };
  }

  public nextPage = (totalPages: number): void => {
    const { pageNumber } = this.state;
    if (pageNumber === totalPages) {
      return;
    }

    this.setState({ pageNumber: pageNumber + 1 });
  };

  public prevPage = (): void => {
    const { pageNumber } = this.state;
    if (pageNumber === 1) {
      return;
    }

    this.setState({ pageNumber: pageNumber - 1 });
  };


  public render(): React.ReactNode {
    const { rrpStore } = this.props;
    const { itemsPerPage, pageNumber } = this.state;
    const { getEntriesInView } = rrpStore;

    const processedRequests = _.filter(rrpStore.recentRrp, (request: Rrp) => request.status !== 'pending');
    const rrpOrdered = _.orderBy(processedRequests, 'dateProcessed', 'desc');
    const recentActivities = _.filter(rrpOrdered, (request: Rrp) => rrpStore.filterDate(request));

    const offset = (pageNumber - 1) * itemsPerPage;
    const recentActivity = getEntriesInView(recentActivities, offset, itemsPerPage);
    const totalItems = recentActivities.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="recent-activity">
        <Paper className="paper-padding top-space-sm">
          <div
            style={
              {
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '32px',
                marginBottom: '48px',
                padding: '8px',
              }
            }
          >
            Recent Activity (
            {totalItems}
            )
          </div>
          <div className="flex-row-table">
            <div className="flex-row-table-header">
              <div className="first-header-column-no-flex">Consultant</div>
              <div>Date Processed</div>
              <div>Allocation</div>
              <div>Action</div>
            </div>
            <div className="flex-row-table-body">
              {
                recentActivity && recentActivity.length > 0 ? recentActivity.map((item: Rrp) => {
                  return <RecentActivityList key={item.id} data={item} />;
                }) : null
              }
            </div>
            <div className="flex-row-table-footer">
              <Pagination
                pageNumber={pageNumber}
                totalPages={totalPages}
                prevPage={this.prevPage}
                nextPage={this.nextPage}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

export default RecentActivities;
