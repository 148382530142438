import Params from './Params';

export interface IRequestArgs {
  method: string;
  url: string;
  headers?: Params;
  body?: string;
  params?: Params;
}
export default class Request {
  public url: string;

  public headers: Params;

  public method: string;

  public body?: string;

  public params: Params;

  constructor(options: IRequestArgs) {
    this.url = options.url;
    this.headers = new Params(options.headers);
    this.method = options.method || 'GET';
    this.body = options.body || undefined;
    this.params = new Params(options.params);
  }
}
