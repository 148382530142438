import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import approvalQueueStyles from './ApprovalQueue.styles';
import { RrpStore } from '../../../stores';
import PendingRequests from './PendingRequests/index';
import RecentActivities from './RecentActivities';

const GlobalStyle = createGlobalStyle`${approvalQueueStyles}`;

interface IProps {
  rrpStore: RrpStore;
}

@inject('rrpStore')
@observer
class ApprovalQueue extends React.Component<IProps> {
  public render(): React.ReactNode {
    const { rrpStore } = this.props;

    return (
      <div className="paper-padding">
        <GlobalStyle />
        <PendingRequests rrpStore={rrpStore} />
        <RecentActivities rrpStore={rrpStore} />
      </div>
    );
  }
}

export default ApprovalQueue;
