import { css } from 'styled-components'; 

const recentActivityStyles = css`
  .list-container:last-child {
    border-bottom: 0px solid transparent;
  }

  .approved {
    color: #4cb57c;
  }

  .rejected, .reject {
    color: #a43b16;
  }
`;

export default recentActivityStyles;
