import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import { createGlobalStyle } from 'styled-components';

import numberInputStyles from './NumberInput.styles';

const GlobalStyle = createGlobalStyle`${numberInputStyles}`;

interface IState {
  numberValue: React.ReactText;
}

interface IProps {
  isError?: boolean;
  isRequired?: boolean;
  name: string;
  label: string;
  min?: number;
  max?: number;
  handleReturnValue: (value: string, state: string) => void;
  state: string;
  disabled?: boolean;
  numberValue?: React.ReactText;
  selectedValue?: React.ReactText;
  errorMessage?: string;
  pattern?: RegExp;
  helperText?: string;
}

class NumberInput extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      numberValue: props.numberValue || '',
    };
  }

  public static getDerivedStateFromProps(props : IProps, state: IState) : IState | null {
    const { selectedValue } = props;
    const { numberValue } = state;
    if (selectedValue !== numberValue) {
      return {
        numberValue: selectedValue || ''
      };
    }
    return null;
  }

  private setValue = (value: string, parsedValue: number): void => {
    const { handleReturnValue, state } = this.props;
    this.setState({
      numberValue: parsedValue,
    });
    handleReturnValue(value, state);
  };

  // check if value is within the min-max range when min-max is set.
  private checkValue = (value: string): void => {
    const { min, max } = this.props;
    const parsedValue = parseFloat(value);
    if (min && max) {
      if ((parsedValue >= min && parsedValue <= max) || value === '') {
        this.setValue(value, parsedValue);
      }
    } else {
      this.setValue(value, parsedValue);
    }
  };

  // Check for patterns first
  private handleUpdateInput = (input: React.ChangeEvent<HTMLInputElement>): void => {
    const { pattern } = this.props;
    const inputValue = input.target.value;
    if (pattern) {
      if (pattern.test(inputValue) || inputValue === '') {
        this.checkValue(inputValue);
      }
    } else {
      this.checkValue(inputValue);
    }
  };

  public render(): React.ReactNode {
    const { isError, name, label, disabled, isRequired, helperText, max, min } = this.props;
    const { numberValue } = this.state;
    return (
      <div>
        <GlobalStyle />
        <TextField
          error={isError}
          inputProps={
            {
              name,
              max,
              min,
            }
          }
          value={numberValue}
          onChange={this.handleUpdateInput}
          label={label}
          fullWidth
          className="numTextField"
          type="number"
          disabled={disabled}
          required={isRequired}
          helperText={helperText}
        />
      </div>
    );
  }
}

export default NumberInput;
