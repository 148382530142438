import { inject, observer } from 'mobx-react';
import * as React from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import AccountStore from 'stores/AccountStore';
import { Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';


interface IProps {
  // eslint-disable-next-line react/require-default-props
  accountStore?: AccountStore;
  children: React.ReactNode;
}

function AuthenticatedComponent(props: IProps) : React.ReactElement {
  const { accountStore, children} = props;
  const location = useLocation();

  return (
    <>
        {
        accountStore && accountStore.isAuthenticated ? children : (
          <Redirect to={
          {
            pathname: '/login',
            state: { from: location.pathname }
          }
          }
          />
        )
        }
    </>
  );
}

export default inject('accountStore')(observer(AuthenticatedComponent));
