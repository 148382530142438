import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import firebase from 'firebase/app';

import { APP_SETTINGS_COLLECTION, APP_SETTINGS_NODE_ID } from '../../constants/constants';

export default class SettingsStore {
  private firebaseApp: firebase.app.App;

  @observable
  public emailRawTemplate = '';

  public static createStore(firebaseApp: firebase.app.App): SettingsStore {
    return new SettingsStore(firebaseApp);
  }

  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    makeObservable(this);
  }    

  @action('SettingsStore:getAppSettings')
  public async getAppSettings() : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const docPermissions = fs.collection(APP_SETTINGS_COLLECTION);
    const snapshot = await docPermissions.get();
    snapshot.forEach((doc) => {
      const { emailTemplate } = doc.data();
      runInAction(() => {
        this.emailRawTemplate = emailTemplate;
      });
    });
  }  

  public async updateEmailTemplate(template: string, plaintext: string) : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const docRef = fs.collection(APP_SETTINGS_COLLECTION);

    await docRef.doc(APP_SETTINGS_NODE_ID).update({
      emailTemplate: template,
      emailPlainText: plaintext
    });
  }   
}
