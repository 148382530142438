import { css } from 'styled-components'; 

const emailAutocompleteStyles = css`
  .parent-container {
    position: relative;
  }
  .paper-container {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    margin-top: 8px;
  }
`;

export default emailAutocompleteStyles;
