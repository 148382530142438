import * as React from 'react';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Clear from '@material-ui/icons/Clear';
import Search from '@material-ui/icons/Search';

interface IProps {
  disabled?: boolean;
  fullWidth: boolean;
  value: string;
  placeholder: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onClear: () => void;
}

const SearchBox: React.FunctionComponent<IProps> = (props: IProps) => {
  const { disabled, fullWidth, value, placeholder, onChange, onClear } = props;
  return (
    <FormControl className="searchBox" fullWidth>
      <InputLabel>Search</InputLabel>
      <Input
        id="input-with-icon-adornment"
        placeholder={placeholder}
        fullWidth={fullWidth}
        disabled={disabled}
        onChange={onChange}
        value={value}
        startAdornment={
(
  <InputAdornment position="start">
    <Search />
  </InputAdornment>
        )
}
        endAdornment={
          value ? (
            <InputAdornment
              className="clear-field"
              disablePointerEvents={false}
              onClick={onClear}
              position="end"
            >
              <Clear />
            </InputAdornment>
          ) : null
        }
      />
    </FormControl>
  );
};

SearchBox.defaultProps = {
  disabled : false
};

export default SearchBox;
