import { Provider } from 'mobx-react';
import * as React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import grey from '@material-ui/core/colors/grey';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import App from './App';
import {
  AccountStore,
  CodaEmployeesStore,
  CodaProjectsStore,
  CodaRRPLogMasterStore,
  FirebaseStore,
  ProjectsStore,
  RrpStore,
  SettingsStore,
  UserStore,
  DealOriginStore
} from '../../stores';

const codaEmployeesStore = CodaEmployeesStore.createStore();
const codaProjectsStore = CodaProjectsStore.createStore();
const codaRRPLogMasterStore = CodaRRPLogMasterStore.createStore();
const firebaseStore = FirebaseStore.createStore();
const projectsStore = ProjectsStore.createStore();
const rrpStore = RrpStore.createStore(firebaseStore.firebase);
const accountStore = AccountStore.createStore(firebaseStore.firebase);
const settingsStore = SettingsStore.createStore(firebaseStore.firebase);
const userStore = UserStore.createStore(firebaseStore.firebase);
const dealOriginStore = DealOriginStore.createStore(firebaseStore.firebase);

const theme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:after': {
          borderBottomColor: '#999',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '2px solid #999 !important',
        },
      },
    },
  },
  palette: {
    primary: grey,
  },
});

const states = {
  accountStore,
  codaEmployeesStore,
  codaProjectsStore,
  codaRRPLogMasterStore,
  firebaseStore,
  projectsStore,
  rrpStore,
  settingsStore,
  userStore,
  dealOriginStore
};

export default (): React.ReactElement => (
  <div>
    <MuiThemeProvider theme={theme}>
      <Provider {...states}>
        <Router>
          <App />
        </Router>
      </Provider>
    </MuiThemeProvider>
  </div>
);
