import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as React from 'react';

const NoDataPlaceholder = () : React.ReactElement => {
  return (
    <Paper className="paper-padding">
      <Typography
        align="center"
        variant="overline"
      >
        No data to display
      </Typography>
    </Paper>
  );
};

export default NoDataPlaceholder;
