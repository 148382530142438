import { css } from 'styled-components'; 

const styles = css`
  .center-contents {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
  }  

  .avatar-deal-origin-list {
    font-size: 14px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.5438);
  }

  .list-container {
    border-bottom: 1px solid #979797;
    padding: 16px;
    display: flex;
    flex-flow: row wrap;
    > div:first-child {
      width: 350px;
      flex: none;
    }
  }  

  .list-container > div {
    flex: 1 1 0%;
  } 
  
  .flex-row-table .flex-row-table-header > div {
    flex: 1 1 0%;
  }  

  .flex-row-table .flex-row-table-header {
    flex: 1 1 0%;
  }
`;

export default styles;
