import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import styles from './ListIdentity.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IListItemProps {
  name: string;
  email: string;
  dealOrigin?: string;
}

const listStyle = {
  padding: 0
};

function ListIdentity(props: IListItemProps) : React.ReactElement {
  const { name, email, dealOrigin } = props;
  return (
    <>
      <GlobalStyle />
      <List style={listStyle}>
        <ListItem>
          <ListItemText>
            <div className="avatar-name-list">{name || email}</div>
            {email ? <div className="avatar-email-list">{email}</div> : null}
            {
              dealOrigin ? (
                <div className="avatar-deal-origin-list">
                  FM
                  {dealOrigin}
                </div>
              ) : null
            }
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
}

ListIdentity.defaultProps = {
  dealOrigin: '',
};

export default ListIdentity;
