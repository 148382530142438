import { css } from 'styled-components'; 

const styles = css`
  .badge span {
    top: 12px !important;
    right: -20px !important;
  }

  .avatar-img {
    background-color: #e0e0e0 !important;
    width: 56px !important;
    height: 56px !important;    
  }

  .avatar-img-container,
  .avatar-img-details {
    padding: 10px !important;
  }  

  .avatar-ui-container {
    padding-top: 16px !important;
  }  

  .main-drawer,
  .main-drawer-paper {
    width: 250px !important;
  }

  .app-drawer-back-icon {
    color: #fff;
  }
  
  .app-drawer-header {
    position: relative;
    height: 144px;
    background-color: #00567b;
    padding: 24px;
  }

  .active-drawer-menu {
    color: #e20078 !important;
    fill: #e20078 !important;
    span {
      color: #e20078 !important;
      fill: #e20078 !important;
    }
  }

  .avatar-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #ffffff;
  }

  .avatar-logout-link {
    position: absolute;
    bottom: 24px;
    left: 95px;
    font-size: 12px;
    line-height: 16px;
    color: #ffffff;
    cursor: pointer;
  }
  
  .avatar-close-drawer-icon {
    position: absolute;
    right: 0px;
    top: 0px;
  }  
`;

export default styles;
