import _ from 'lodash';
import { action, makeObservable, observable, runInAction } from 'mobx';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import firebase from 'firebase/app';

import { DEAL_ORIGINS_COLLECTION } from '../../constants/constants';

export default class DealOriginStore {
  private firebaseApp: firebase.app.App;

  @observable
  public dealOrigins : string[] = [];

  public static createStore(firebaseApp: firebase.app.App): DealOriginStore {
    return new DealOriginStore(firebaseApp);
  }

  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    makeObservable(this);
  }    

  @action('SettingsStore:getDealOrigins')
  public async getDealOrigins() : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const docs = fs.collection(DEAL_ORIGINS_COLLECTION);
    const snapshot = await docs.get();
    snapshot.forEach((doc) => {
      const { dealOrigins } = doc.data();
      runInAction(() => {
        this.dealOrigins = dealOrigins;
      });
    });
  }  
}
