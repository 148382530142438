import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import NoDataPlaceholder from '../../../../Common/NoDataPlaceholder';
import ProjectsViewRows from '../../Blocks/ProjectsViewRows';
import TotalHoursRow from '../../Blocks/TotalHoursRow';
import styles from '../ViewsCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IProjectCodes {
  displayText: string;
  value: string;
  dealOrigin: string;
}
interface ITableProps {
  code: string;
  origin: string;
  data: IReportDataJson[];
}

interface IViewProps {
  employeeList: IEmployeeResponse[];
  filteredData: IReportDataJson[];
  projectCodes: IProjectCodes[];
}

class ProjectsView extends React.Component<IViewProps> {

  public renderTables = (tableData: ITableProps[]) : React.ReactNode => {
    const { employeeList } = this.props;

    return tableData.length > 0 ? tableData.map((table: ITableProps) => {
      const getNamesandEmails = table.data.map(data => ({
        ...data,
        email: data.consultant.email
      }));

      const sortTableData = _.sortBy(getNamesandEmails, 'email');

      return table.data.length !== 0 ? (
        <Paper key={`${table.origin}-${table.code}`} className="paper-padding result-tables">
          <div className="table-title">
            {`${table.origin} \\ ${table.code}`}
          </div>
          <div className="flex-row-table">
            <div className="table-container">
              <div className="flex-row-table-header">
                <div className="first-header-column-no-flex">Consultant</div>
                <div>Requested By</div>
                <div>Allocation</div>
              </div>
            </div>
            <div className="flex-row-table-body">
              {
                sortTableData.map(
                  (row: IReportDataJson) => (
                    <ProjectsViewRows employeeList={employeeList} key={row.id} data={row} />
                  )
                )
              }
              <TotalHoursRow entries={table.data} />
            </div>
          </div>
        </Paper>
      ) : null;
    }) : <NoDataPlaceholder />;
  };


  public render() : React.ReactNode {
    const {
      filteredData,
      projectCodes,
    } = this.props;

    let viewData: ITableProps[] = [];

    if (projectCodes) {
      viewData = projectCodes.map((code) => {
        const entries = _.filter(filteredData, ['projectCode', code.value]);
        return { code: code.value, origin: code.dealOrigin, data: entries };
      });
    }

    return (
      <>
        <GlobalStyle />
        {this.renderTables(viewData)}
      </>
    );
  }
}

export default ProjectsView;
