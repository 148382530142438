import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { action, computed, makeObservable, observable } from 'mobx';
import AccountsDAO from './AccountDAO';

export default class AccountStore {
  private authPersistence = firebase.auth.Auth.Persistence.LOCAL;

  private accountDao: AccountsDAO;

  private firebaseApp: firebase.app.App;

  public firebaseAuth: firebase.auth.Auth;

  @observable
  public error = '';

  @observable
  public token: string | null = null;

  @observable
  public pending = false;

  @observable
  public currentUser: firebase.User | undefined = undefined;

  @computed
  public get isAuthenticated() : boolean {
    return !!this.currentUser;
  }

  public static createStore(fbApp: firebase.app.App) : AccountStore {
    return new AccountStore(fbApp);
  }

  constructor(fbApp: firebase.app.App) {
    const dao = new AccountsDAO(fbApp);
    this.accountDao = dao;
    this.firebaseApp = fbApp;
    this.firebaseAuth = this.firebaseApp.auth();
    this.firebaseAuth.setPersistence(this.authPersistence);
    makeObservable(this);
  }

  @action('AccountStore:setUser')
  public setUser(user: firebase.User | undefined) : void {
    this.currentUser = user;
  }

  @action('AccountStore:login')
  public async login() : Promise<void> {
    await this.accountDao.login();
  }

  @action('AccountStore:logoutUser')
  public async logoutUser() : Promise<void> {
    firebase
      .auth()
      .signOut()
      .then(() => {
        action('logoutSuccess', () => {
          this.currentUser = undefined;
        });
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
