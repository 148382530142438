import * as _ from 'lodash';
import * as React from 'react';

import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Clear from '@material-ui/icons/Clear';
import { ITextValue } from '../../../../interfaces/ITextValue';

interface IState {
  selectValue: string | number | undefined;
}

interface IProps {
  isError?: boolean;
  isRequired?: boolean;
  name: string;
  data: ITextValue[];
  label: string;
  state: string;
  selectedValue?: string | number;
  handleReturnValue: (value: string, state: string) => void;
  orderByText?: boolean;
}

class TextInput extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      selectValue: '',
    };
  }

  public static getDerivedStateFromProps(props : IProps, state: IState) : IState | null {
    const { selectedValue } = props;
    const { selectValue } = state;
    if (selectedValue !== selectValue) {
      return {
        selectValue: selectedValue
      };
    }
    return null;
  }

  private handleSelectMenuList = (): React.ReactNode[] => {
    const { data, orderByText } = this.props;

    const menuLists: React.ReactNode[] = [];
    const orderData = orderByText ? _.orderBy(data, ['text'], ['asc']) : data;

    orderData.forEach((row) => {
      menuLists.push(
        <MenuItem key={row.displayText} value={row.value}>
          {row.displayText}
        </MenuItem>
      );
    }
    );
    return menuLists;
  };

  private handleClear = (): void => {
    this.setValue('');
  };

  private handleUpdateInput = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setValue(event.target.value);
  };

  private setValue = (value: string): void => {
    const { handleReturnValue, state } = this.props;
    this.setState({
      selectValue: value,
    });
    handleReturnValue(value, state);
  };


  public render(): React.ReactNode {
    const { name, label, state, isError, isRequired } = this.props;
    const { selectValue } = this.state;
    return (
      <div>
        <FormControl fullWidth>
          <TextField
            id={name}
            name={name}
            label={label}
            select
            fullWidth
            InputProps={
              {
                endAdornment:
                  (state !== 'month' && state !== 'year' && selectValue !== '') ? (
                    <InputAdornment
                      className="clear-field"
                      disablePointerEvents={false}
                      onClick={this.handleClear}
                      position="end"
                    >
                      <Clear />
                    </InputAdornment>
                  ) : null
              }
            }
            onChange={this.handleUpdateInput}
            value={selectValue}
            error={isError}
            required={isRequired}
          >
            {this.handleSelectMenuList()}
          </TextField>
        </FormControl>
      </div>
    );
  }
}

export default TextInput;
