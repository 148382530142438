import { Coda } from 'coda-js';
import { IObservableArray, observable } from 'mobx';
import Project, { IProjectsJson } from 'stores/models/Project';

const apiToken = process.env.REACT_APP_CODA_API_TOKEN ? process.env.REACT_APP_CODA_API_TOKEN : '';

// Doc: RRP Data
const docId = 'lDhl33hv_D';

// Table: Projects in Page: Sales Data
const tableId = 'grid-sync-1054-Table-dynamic-c83848d1c14c5306a4656f94fe57798a092c2c19852766103f5e33a18127ab2f';

interface IProjectCodes {
  code: string;
  name: string;
  id: string;
}

interface IValues {
  'Billing Status': string,
  'Client': string,
  'End Date': string,
  'Has Closed': boolean,
  'Is Perpetual': boolean,
  'Project Code': string,
  'Project Name': string,
  'Row': string,
  'Start Date': string,
  'Sync account': string,
  'Synced': boolean,
}

export default class CodaProjectsStore {

  @observable
  public projectsList: IObservableArray<Project> = observable([]);

  @observable
  public projectCodes: IProjectCodes[] = [];

  @observable
  public projectCodesLabel: string[] = [];

  public static createStore(): CodaProjectsStore {
    return new CodaProjectsStore();
  }

  public async connect() : Promise<void> {
    const coda = new Coda(apiToken);

    const table = await coda.getTable(docId, tableId);
    const rows = await table.listRows({
      useColumnNames: true,
    });

    const tempProjectCodes: IProjectCodes[] = [];
    const tempProjectCodesLabel: string[] = [];

    const mappedRows = rows.map((row) => {
      const values = row.values as IValues;

      const rowId = row.id ? row.id : '';
      const billingStatus = values['Billing Status'];
      // eslint-disable-next-line dot-notation
      const clientName = values['Client'];
      const projectCode = values['Project Code'];
      const projectName = values['Project Name'];

      tempProjectCodes.push({ id: rowId, code: projectCode, name: projectName });
      tempProjectCodesLabel.push(`${projectCode} | ${projectName}`);

      // Construct data with filled relevant constructor variables
      const data: IProjectsJson = {
        id: rowId,
        billing_status: billingStatus,
        client_id: '',
        client_name: clientName,
        code: projectCode,
        name: projectName,
        description: '',
        entity_id: '',
        end_date: '',
        expiry_date: '',
        engagement_manager_id: null,
        hours: null,
        start_date: '',
      };

      return new Project({...data});
    });

    this.projectsList = observable(mappedRows);
    this.projectCodes = tempProjectCodes;
    this.projectCodesLabel = tempProjectCodesLabel;
  }
}
