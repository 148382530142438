import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Spinner from 'components/Common/Spinner/Spinner';
import Button from '@material-ui/core/Button';
import { createGlobalStyle } from 'styled-components';

import { AccountStore } from '../../../stores';
import styles from './Login.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IProps {
  accountStore: AccountStore;
}

interface IState {
  isLoading: boolean;
}

let timerHandle : null | ReturnType<typeof setTimeout> = null;

@inject('accountStore')
@observer
class Login extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  public componentDidMount = () : void => {
    // keep an initial delay before showing content as the auth mechanism may kick in, in the interim
    timerHandle = setTimeout(
      () => {
        this.setState({ isLoading: false });
      },
      2000
    );
  };

  public componentWillUnmount = () : void => {
    if (timerHandle) {
      clearTimeout(timerHandle);
    }
  };

  public onLogin = async (): Promise<void> => {
    const { accountStore } = this.props;
    if (accountStore) {
      await accountStore.login();
    }
  };

  public render(): React.ReactNode {
    const { isLoading } = this.state;

    if (isLoading) {
      return (
      <div className="spinner-full-page">
        <Spinner />
      </div>
      );
    }

    return (
      <>
        <GlobalStyle />
        <div className="login-div">
          <Button onClick={this.onLogin} color="secondary" variant="contained">
            Sign in with Google
          </Button>
        </div>
      </>
    );
  }
}

export default Login;
