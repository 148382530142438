import Paper from '@material-ui/core/Paper';
import React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import NoDataPlaceholder from '../../../../Common/NoDataPlaceholder';
import Pagination from '../../../../Common/Pagination';
import ApprovedRRP from '../../Blocks/ApprovedRRP';
import styles from '../ViewsCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IViewProps {
  viewData: IReportDataJson[];
  pageNumber: number;
  totalPages: number;
  projectCode: string;
  dealOrigin: string;
  nextPage: (totalPages: number) => void;
  prevPage: () => void;
  employeeList: IEmployeeResponse[];
}

const DefaultView: React.FC<IViewProps> = (props) => {
  const {
    viewData,
    pageNumber,
    totalPages,
    nextPage,
    projectCode,
    dealOrigin,
    prevPage,
    employeeList
  } = props;

  let headerToRender = '';
  if (projectCode !== '' && dealOrigin !== '') {
    headerToRender = `${dealOrigin} / ${projectCode}`;
  } else if (projectCode) {
    headerToRender = projectCode;
  } else if (dealOrigin) {
    headerToRender = dealOrigin;
  }

  return (
    <>
      <GlobalStyle />
      <Paper className="paper-padding">
        <div className="table-title">
          {headerToRender}
        </div>
        <div className="flex-row-table">
          <div className="table-container">
            <div className="flex-row-table-header">
              <div className="first-header-column-no-flex">Consultant</div>
              <div>Project Code</div>
              <div>Deal Origin</div>
              <div>Requested By</div>
              <div>Allocation</div>
            </div>
          </div>
          <div className="flex-row-table-body">
            {
              viewData.length > 0 ? viewData.map((data: IReportDataJson) => {
                return <ApprovedRRP key={data.id} data={data} employeeList={employeeList} />;
              }) : <NoDataPlaceholder />
            }
          </div>
          <div className="flex-row-table-footer">
            <Pagination
              pageNumber={pageNumber}
              totalPages={totalPages}
              prevPage={prevPage}
              nextPage={nextPage}
            />
          </div>
        </div>
      </Paper>
    </>
  );
};

export default DefaultView;
