import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { RrpStore } from '../../../../../stores';
import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import ListIdentity from '../../../../Common/ListIdentity';
import NoDataPlaceholder from '../../../../Common/NoDataPlaceholder';
import ConsultantsViewRows from '../../Blocks/ConsultantsViewRows';
import TotalHoursRow from '../../Blocks/TotalHoursRow';
import styles from '../ViewsCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface ITableProps {
  employeeEmail: string;
  data: IReportDataJson[];
}

interface IViewProps {
  employeeList: IEmployeeResponse[];
  filteredData: IReportDataJson[];
  rrpStore?: RrpStore;
}

interface IViewState {
  viewData: ITableProps[];
}

@inject('rrpStore')
@observer
class DealOriginView extends React.Component<IViewProps, IViewState> {

  constructor(props: IViewProps) {
    super(props);
    this.state = {
      viewData: [],
    };

  }

  public componentDidMount() : void {
    const { filteredData } = this.props;
    this.organizeData(filteredData);
  }

  public componentDidUpdate(prevProps: IViewProps) : void {
    const { filteredData } = this.props;
    if (filteredData !== prevProps.filteredData) {
      this.organizeData(filteredData);
    }
  }

  public organizeData(rawData: IReportDataJson[]) : void {
    const filteredData = rawData;
    const uniqByEmail = _.uniqBy(filteredData, entry => entry.consultant.email.trim());

    let viewData: ITableProps[] = [];

    viewData = uniqByEmail.map((entry) => {
      const entries = _.filter(filteredData, data => (data.consultant.email.trim() === entry.consultant.email.trim()));

      return {
        data: entries,
        employeeEmail: entry.consultant.email.trim()
      };
    });

    viewData = _.filter(viewData, consultantData => (consultantData.data.length > 0));

    const sortedData = _.sortBy(viewData, 'employeeEmail');
    this.setState({ viewData: sortedData });
  }

  public renderTables = (viewData: ITableProps[]) : React.ReactNode => {
    return viewData.length > 0 ? viewData.map((table: ITableProps) => {
      const { employeeList } = this.props;
      const { employeeEmail, data } = table;
      const consultantInfo = employeeList.find(employee => employee.email === employeeEmail);
      const consultantName = consultantInfo ? `${consultantInfo.given_name} ${consultantInfo.family_name}` : '';

      return table.data.length !== 0 ? (
        <Paper key={employeeEmail} className="paper-padding result-tables">
          <div className="table-title">
            <ListIdentity
              name={consultantName}
              email={employeeEmail}
            />
          </div>
          <div className="flex-row-table">
            <div className="table-container">
              <div className="flex-row-table-header">
                <div className="first-header-column-no-flex">Project Code</div>
                <div>Deal Origin</div>
                <div>Allocation</div>
              </div>
            </div>
            <div className="flex-row-table-body">
              {data.map((row: IReportDataJson) => <ConsultantsViewRows key={row.id} data={row} />)}
              <TotalHoursRow entries={data} />
            </div>
          </div>
        </Paper>
      ) : null;
    }) : <NoDataPlaceholder />;
  };

  public render() : React.ReactNode {
    const { viewData } = this.state;

    return (
      <>
        <GlobalStyle />
        {this.renderTables(viewData)}
      </>
    );

  }
}

export default DealOriginView;
