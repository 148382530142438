import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';

import Auth from 'components/Auth/Auth';
import appStyles, { Header } from './App.styles';
import DrawerLayout from './Layout/Drawer';
import AuthenticatedComponent from '../Common/ProtectedRoute';
import ApprovalQueue from '../Pages/ApprovalQueue';
import ErrorPage from '../Pages/ErrorPage';
import NewRequest from '../Pages/NewRequest';
import Reports from '../Pages/Reports';
import Settings from '../Pages/Settings';
import Login from '../Pages/Login';

import {
  AccountStore,
  CodaEmployeesStore,
  FirebaseStore,
  ProjectsStore,
  RrpStore,
} from '../../stores';

const GlobalStyle = createGlobalStyle`${appStyles}`;

interface IState {
  navDrawerOpen: boolean;
}

interface IProps extends RouteComponentProps {
  accountStore?: AccountStore;
  codaEmployeesStore?: CodaEmployeesStore;
  firebaseStore?: FirebaseStore;
  projectsStore?: ProjectsStore;
  rrpStore?: RrpStore;
}

@inject(
  'accountStore',
  'codaEmployeesStore',
  'firebaseStore',
  'projectsStore',
  'rrpStore',
)
@observer
class App extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      navDrawerOpen: false,
    };
  }

  public handleDrawerToggleFromDrawer = (value: boolean): void => {
    this.setState({ navDrawerOpen: value });
  };

  private handleDrawerToggle = (): void => {
    const { navDrawerOpen } = this.state;
    this.setState({ navDrawerOpen: !navDrawerOpen });
  };

  public render(): React.ReactNode {
    const { accountStore } = this.props;
    const { navDrawerOpen } = this.state;

    const navIcon = accountStore?.isAuthenticated ? (
      <IconButton onClick={this.handleDrawerToggle} className="hamburger-icon" color="inherit" aria-label="Menu">
        <MenuIcon />
      </IconButton>
    ) : null;

    const nav = (
      <Typography variant="h6" color="inherit" className="app-bar-title">
        Resourcing Request Process
      </Typography>
    );

    return (
      <div className="App">
        <GlobalStyle />
        <div>
          <Header>
            <Toolbar>
              {navIcon}
              {nav}
            </Toolbar>
          </Header>
          <DrawerLayout
            openDrawerValue={navDrawerOpen}
            openDrawer={this.handleDrawerToggleFromDrawer}
          />
        </div>
        <Auth>
        <Switch>
          <Route exact path="/login" component={Login} />
          <AuthenticatedComponent>
            <Route exact path="/" component={NewRequest} />
            <Route exact path="/new-request" component={NewRequest} />
            <Route exact path="/approval-queue" component={ApprovalQueue} />
            <Route exact path="/reports" component={Reports} />
            <Route exact path="/settings" component={Settings} />
          </AuthenticatedComponent>
          <Route component={ErrorPage} />
        </Switch>
        </Auth>
      </div>
    );
  }
}

export default withRouter(App);
