import _ from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import moment from 'moment';
// eslint-disable-next-line @typescript-eslint/no-unused-vars-experimental
import firebase from 'firebase/app';

import { ILedgerData } from 'interfaces/ILedgerData';
import TotalAllocationCalculator from '../../utils/TotalAllocationCalculator';
import Rrp from '../models/Rrp';
import { IRrp } from '../../interfaces/IRrp';
import { IReportDataJson } from '../../interfaces/IReportDataJson';
import { IPostLedgerData } from '../../interfaces/IPostLedgerData';
import { LEDGER_COLLECTION, MONTHS_OF_YEAR } from '../../constants/constants';

export default class RrpStore {
  private firebaseApp: firebase.app.App;

  @observable
  public reportData: Rrp[] = [];

  @observable
  public recentRrp: Rrp[] = [];

  public static createStore(firebaseApp: firebase.app.App): RrpStore {
    return new RrpStore(firebaseApp);
  }

  constructor(firebaseApp: firebase.app.App) {
    this.firebaseApp = firebaseApp;
    makeObservable(this);
  }

  @computed
  public get pendingRequests() : Rrp[] {
    const pendingRequests = _.filter(this.recentRrp, ['status', 'pending']);
    const requests = _.orderBy(pendingRequests, 'dateCreated', 'desc');
    return requests && requests.length > 0 ? requests : [];
  }

  public getEntriesInView(data : Rrp[], offset: number, items_per_page: number) : Rrp[] {
    return _.drop(data, offset).slice(0, items_per_page);
  }

  public getEntriesInView2(data : IReportDataJson[], offset: number, items_per_page: number) : IReportDataJson[] {
    return _.drop(data, offset).slice(0, items_per_page);
  }

  public getTotalHours = (entries: IReportDataJson[]) : number => {
    const newCalc = new TotalAllocationCalculator(entries);
    const hours = newCalc.sum('hours');
    return hours;
  };

  public filterDate(request: Rrp) : boolean {
    const dateProcessed = moment.utc(request.dateProcessed);
    const currentDate = moment.utc();
    const previousWeeks = moment.utc(currentDate).subtract(7, 'weeks').startOf('isoWeek').toISOString();
    const currentWeek = moment.utc(currentDate).endOf('isoWeek').toISOString();

    return dateProcessed.isBefore(moment.utc(currentWeek)) && dateProcessed.isAfter(moment.utc(previousWeeks));
  }

  @action('RrpStore:fetchLedger')
  public async fetchLedger(startDateIn: Date, endDateIn: Date) : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const collection = fs.collection(LEDGER_COLLECTION);

    const query = collection
      .where('status', '==', 'approved')
      .where('startDate', '>=', startDateIn)
      .where('startDate', '<=', endDateIn);

    const entries = await query.get();
    const rrpDocs: Rrp[] = [];
    entries.forEach((entry) => {
      const { dateProcessed, dateCreated, startDate, endDate } = this.getDates(entry);

      const data = {
        ...entry.data(),
        dateProcessed,
        dateCreated,
        startDate,
        endDate
      };
      const newRrp = new Rrp(data as IRrp);
      rrpDocs.push(newRrp);
    });
    runInAction(() => {
      this.reportData = rrpDocs;
    });
  }

  @action('RrpStore:postLedger')
  public async postLedger(data: IPostLedgerData) : Promise<void> {
    const monthYear = data.dateValue;
    const monthAndYear = monthYear.split(' ');
    const year = parseFloat(monthAndYear[1]);

    const month = MONTHS_OF_YEAR.indexOf(monthAndYear[0]);
    const monthNum = month ? month + 1 : 0;

    const totalDaysInAMonth = new Date(Date.UTC(year, monthNum, 0)).getDate();

    const start_date = new Date(Date.UTC(year, month, data.fromValue === 0? 1 : data.fromValue));
    const end_date = new Date(Date.UTC(year, month, data.fromValue === 0? totalDaysInAMonth : data.toValue));

    const fs = this.firebaseApp.firestore();
    const docRef = fs.collection(LEDGER_COLLECTION);
    const {id} = docRef.doc();

    const newData : IRrp = {
      id,
      consultant: {
        email: data.email,
        name: data.name,
      },
      consultantId: data.consultantId,
      dateCreated: new Date(),
      dealOrigin: data.dealOrigin,
      endDate: end_date,
      hours: data.hours,
      projectCode: data.projectCode,
      requestedBy: data.currentUser?.id,
      requestor: {
        email: data.currentUser?.email,
        name: `${data.currentUser?.given_name} ${data.currentUser?.family_name}`,
      },
      startDate: start_date,
      status: 'pending',
    };
    if (data.kickoffBrief) {
      newData.kickoffBrief = data.kickoffBrief;
    }
    await docRef.doc(id).set(newData);
    const newRrpArray = this.recentRrp.concat(new Rrp(newData));
    runInAction(() => {
      this.recentRrp = newRrpArray;
    });
  }

  @action('RrpStore:updateRrp')
  public updateRrp(updateData : ILedgerData) : void {
    const rrpToUpdate = this.recentRrp.find(rrp => rrp.id === updateData.id);
    if (rrpToUpdate) {
      rrpToUpdate.status = updateData.status;
      rrpToUpdate.dateProcessed = updateData.dateProcessed;
      rrpToUpdate.approvedBy = updateData.approvedBy;
      rrpToUpdate.approver = updateData.approver;
      const newRrpArray = this.recentRrp.filter(rrp => rrp.id !== updateData.id);
      this.recentRrp = newRrpArray.concat(rrpToUpdate);
    }
  }

  public async updateLedger(data: ILedgerData) : Promise<void> {
    const fs = this.firebaseApp.firestore();
    const ledgerRef = fs.collection(LEDGER_COLLECTION);
    await ledgerRef.doc(data.id).update(data);
  }

  // Get recent requests from the previous 8 weeks onwards
  @action('Rrpstore:getRecentRequests')
  public async getRecentRequests() : Promise<void> {
    const currentDate = moment.utc();
    const previousWeeks = moment.utc(currentDate).subtract(7, 'weeks').startOf('isoWeek').toDate();

    const fs = this.firebaseApp.firestore();
    const ledger = fs
      .collection(LEDGER_COLLECTION)
      .where('dateCreated', '>=', previousWeeks);

    const entries = await ledger.get();
    const rrpDocs: Rrp[] = [];
    entries.forEach((entry) => {
      const { dateProcessed, dateCreated, startDate, endDate } = this.getDates(entry);

      const data = {
        ...entry.data(),
        dateProcessed,
        dateCreated,
        startDate,
        endDate
      };
      const newRrp = new Rrp(data as IRrp);
      rrpDocs.push(newRrp);
    });
    runInAction(() => {
      this.recentRrp = rrpDocs;
    });
  }

  private getDates(entry: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) :
  { dateProcessed: Date, dateCreated: Date, startDate: Date, endDate: Date} {
    // Need manual handling to convert firestore Timestamp data to JS Date type.
    // When date fields are read from firestore, they come in Timestamp format by default
    const dateProcessedTimestamp = entry.get('dateProcessed');
    const dateProcessed = dateProcessedTimestamp? dateProcessedTimestamp.toDate() : undefined;
    const dateCreated = entry.get('dateCreated').toDate();
    const startDate = entry.get('startDate').toDate();
    const endDate = entry.get('endDate').toDate();
    return { dateProcessed, dateCreated, startDate, endDate };
  }
}
