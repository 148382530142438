import moment from 'moment';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { MONTHS_OF_YEAR } from 'constants/constants';
import dateSelectorStyles from './DateSelector.styles';
import NumberInput from '../NumberInput/NumberInput';
import SelectInput from '../SelectInput/SelectInput';

const GlobalStyle = createGlobalStyle`${dateSelectorStyles}`;

interface IMonthList {
  displayText: string;
  value: string;
}

interface IState {
  dateValue: string;
  maxDate: number;
  minDate: number;
  toValue: number;
  fromValue: number;
  specifyDates: boolean;
}

interface IProps {
  isError?: boolean;
  isRequired?: boolean;
  handleDateChange: (value: string, state: string) => void;
  handleReturnInputCheck: (dates: { fromValue: number, toValue: number }) => void;
  name: string;
  label: string;
  selectedValue?: string;
}

class DateSelector extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dateValue: '',
      fromValue: 0,
      maxDate: 30,
      minDate: 1,
      specifyDates: false,
      toValue: 0,
    };
  }

  public componentDidUpdate(prevProps: IProps) : void {
    const { selectedValue } = this.props;
    if (selectedValue !== prevProps.selectedValue) {
      this.handleUpdateInputCheckInternal(false);
    }
  }

  public handleMonthYearUpdate = (value: string, state: string): void => {
    const { handleDateChange } = this.props;
    const monthYear = value;
    const monthAndYear = monthYear.split(' ');
    const year = parseFloat(monthAndYear[1]);
    const month = MONTHS_OF_YEAR.indexOf(monthAndYear[0]);
    const monthNum = month ? month + 1 : 0;
    const totalDaysInAMonth = new Date(Date.UTC(year, monthNum, 0)).getDate();

    this.setState(prevState => ({
      ...prevState,
      [state]: value,
      fromValue: 1,
      maxDate: totalDaysInAMonth,
      toValue: totalDaysInAMonth,
    }));

    handleDateChange(value, state);
  };

  private handleGetLastCurrentAndNextMonth = (): IMonthList[] => {
    const monthList: IMonthList[] = [];
    const currentMonth = moment.utc().format('MMMM YYYY');
    const lastMonth = moment.utc()
      .subtract(1, 'months')
      .format('MMMM YYYY');
    const nextMonth = moment.utc()
      .add(1, 'months')
      .format('MMMM YYYY');
    monthList.push({
      displayText: lastMonth,
      value: lastMonth,
    });
    monthList.push({
      displayText: currentMonth,
      value: currentMonth,
    });
    monthList.push({
      displayText: nextMonth,
      value: nextMonth,
    });
    return monthList;
  };


  private handleUpdateInputCheck = (_: React.ChangeEvent<HTMLInputElement>, isInputChecked: boolean): void => {
    this.handleUpdateInputCheckInternal(isInputChecked);
  };

  private handleUpdateInputCheckInternal = (isInputChecked: boolean): void => {
    const { minDate, maxDate } = this.state;
    const { handleReturnInputCheck } = this.props;
    if (!isInputChecked) {
      this.setState({
        fromValue: 0,
        specifyDates: isInputChecked,
        toValue: 0,
      });
      return;
    }

    this.setState(prevState => ({
      ...prevState,
      fromValue: minDate,
      specifyDates: isInputChecked,
      toValue: maxDate,
    }));
    handleReturnInputCheck({ fromValue: minDate, toValue: maxDate });
  };

  private handleUpdateInputDates = (newValue: string, state: string): void => {
    const { handleDateChange } = this.props;
    const value = parseFloat(newValue);
    this.setState(prevState => ({
      ...prevState,
      [state]: value,
    }));
    handleDateChange(newValue, state);
  };


  public render(): React.ReactNode {
    const { specifyDates, fromValue, maxDate, toValue } = this.state;
    const { name, label, isError, isRequired, selectedValue } = this.props;

    return (
      <div>
        <GlobalStyle />
        <div className="flex-row">
          <div>
            <SelectInput
              isError={isError}
              isRequired={isRequired}
              name={name || 'month-year-dd'}
              label={label}
              data={this.handleGetLastCurrentAndNextMonth()}
              state="dateValue"
              handleReturnValue={this.handleMonthYearUpdate}
              selectedValue={selectedValue}
            />
          </div>
          <div className="flex-row">
            <div className="date-selection-checkbox-container">
              <FormControlLabel
                control={
                  (
                    <Checkbox
                      checked={specifyDates}
                      onChange={this.handleUpdateInputCheck}
                      classes={
                        {
                          checked: 'fm-green',
                        }
                      }
                    />
                  )
                }
                label="Specify Dates:"
              />
            </div>
            <div style={{ paddingLeft: '0px' }}>
              <NumberInput
                name="month-year-date-from"
                label="From"
                min={1}
                max={maxDate}
                numberValue={specifyDates ? fromValue : 0}
                disabled={!specifyDates}
                selectedValue={specifyDates ? fromValue.toString() : ''}
                state="fromValue"
                handleReturnValue={this.handleUpdateInputDates}
              />
            </div>
            <div style={{ paddingRight: '0px', paddingLeft: '0px' }}>
              <NumberInput
                name="month-year-date-to"
                label="To"
                min={1}
                max={maxDate}
                numberValue={specifyDates ? toValue : 0}
                disabled={!specifyDates}
                selectedValue={specifyDates ? toValue.toString() : ''}
                state="toValue"
                handleReturnValue={this.handleUpdateInputDates}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DateSelector;
