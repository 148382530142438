import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';
import paginationStyles from './Pagination.styles';

const GlobalStyle = createGlobalStyle`${paginationStyles}`;

interface IPaginationProps {
  pageNumber: number;
  totalPages: number;
  prevPage: () => void;
  nextPage: (totalPages: number) => void;
}

const Pagination: React.FunctionComponent<IPaginationProps> = (props) => {
  const { pageNumber, totalPages, prevPage, nextPage } = props;

  const onNextPage = () : void => {
    nextPage(totalPages);
  };
  const onPrevPage = () : void => {
    prevPage();
  };

  const isPages = totalPages !== 0;

  return (
    <div className="flex-footer-nav">
      <GlobalStyle />
      <div className="indicator">
        <Typography variant="subtitle2">{`${isPages ? pageNumber : 0} / ${totalPages}`}</Typography>
      </div>
      {
        isPages
          ? (
            <div className="navigation">
              <Button color="secondary" size="small" variant="contained" onClick={onPrevPage}>PREV</Button>
              <Button color="secondary" size="small" variant="contained" onClick={onNextPage}>NEXT</Button>
            </div>
          ) : null
      }
    </div>
  );
};

export default Pagination;
