import { Coda } from 'coda-js';
import { observable } from 'mobx';
import { IEmployeeResponse } from 'interfaces/IEmployeeResponse';

const apiToken = process.env.REACT_APP_CODA_API_TOKEN ? process.env.REACT_APP_CODA_API_TOKEN : '';

// Doc: RRP Data
const docId = 'lDhl33hv_D';

// Table: Employees in Page: Staff Data
// Column: Positions === 0, retired from company
const tableId = 'grid-sync-1054-Table-dynamic-7845605b6aeb5625bfa592deeed99033821ce4174c7aab08ff465ca48c378293';

interface IEmployeeNameList {
  name: string;
  email: string;
  id: string;
}

interface IValues {
  'Company Email': string,
  'Family Name': string,
  'Given Name': string,
  'Mobile Number': string,
  'Positions': number
  'Row': string,
  'Sync account': string,
  'Synced': boolean,
}

export default class CodaEmployeesStore {

  @observable
  public employeesList: IEmployeeResponse[] = [];

  @observable
  public employeeNameEmail: IEmployeeNameList[] = [];

  @observable
  public employeeNameEmailLabel: string[] = [];

  public static createStore(): CodaEmployeesStore {
    return new CodaEmployeesStore();
  }

  public async connect() : Promise<void> {
    const coda = new Coda(apiToken);

    const table = await coda.getTable(docId, tableId);
    const rows = await table.listRows({
      useColumnNames: true,
    });

    // Remove retired employees
    const filteredRows = rows.filter((row) => {
      const values = row.values as IValues;
      return values.Positions > 0;
    });

    const tempEmployeeNameEmail: IEmployeeNameList[] = [];
    const tempEmployeeNameEmailLabel: string[] = [];

    const mappedRows = filteredRows.map((row) => {
      const values = row.values as IValues;

      const rowId = row.id ? row.id : '';
      const givenName = values['Given Name'];
      const familyName = values['Family Name'];
      const fullName = `${givenName} ${familyName}`;
      const companyEmail = values['Company Email'];
      const mobileNumber = values['Mobile Number'];

      tempEmployeeNameEmail.push({ id: rowId, email: companyEmail, name: fullName });
      tempEmployeeNameEmailLabel.push(`${fullName} | ${companyEmail}`);

      return {
        id: rowId,
        family_name: familyName,
        given_name: givenName,
        nickname: givenName,
        image_url: '',
        department: '',
        location: '',
        teams: [],
        skills: [],
        email: companyEmail,
        phone: mobileNumber,
        user_id: rowId,
        user_type: '',
      } as IEmployeeResponse;
    });

    this.employeesList = mappedRows as IEmployeeResponse[];
    this.employeeNameEmail = tempEmployeeNameEmail;
    this.employeeNameEmailLabel = tempEmployeeNameEmailLabel;
  }
}