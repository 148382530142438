import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import _ from 'lodash';
import { createGlobalStyle } from 'styled-components';

import { PermissionLabels, PermissionKeys } from 'constants/permissions';
import RrpStore from 'stores/RrpStore';
import DialogBox from 'components/Common/DialogBox';
import { IRrpUser } from 'interfaces/IRrpUser';
import { CodaEmployeesStore, UserStore } from 'stores';
import styles from './UserPermissions.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

type userPermissionsType = { key: string; value: string; isChecked: boolean };

interface IState {
  open: boolean;
  usersPermissionAndState: userPermissionsType[];
  submitError: string | undefined;
}

interface IProps {
  codaEmployeesStore?: CodaEmployeesStore;
  permissions: string[];
  email: string;
  family_name: string;
  given_name: string;
  location: string;
  rrpStore?: RrpStore;
  userStore? : UserStore;
}

@inject('codaEmployeesStore', 'rrpStore', 'userStore')
@observer
class UserPermissions extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    const { permissions } = this.props;
    const usersPermissionAndState: userPermissionsType[] = [];

    PermissionKeys.forEach((key: string) => {
      const isChecked = permissions.includes(key);
      usersPermissionAndState.push({ isChecked, key, value: PermissionLabels[key] });
    });

    this.state = {
      usersPermissionAndState,
      open: false,
      submitError: undefined
    };
  }

  private onOpenDialog = (): void => {
    this.setState({ open: true });
  };

  private handleClose = (): void => {
    this.setState({ open: false });
  };

  private clearErrorModal = (): void => {
    this.setState({ submitError: undefined });
  };

  private onChangePermission = async (): Promise<void> => {
    const { usersPermissionAndState } = this.state;
    const { email, userStore, codaEmployeesStore } = this.props;
    const permissions: string[] = [];

    usersPermissionAndState.forEach((permission) => {
      if (permission.isChecked === true) {
        permissions.push(permission.key);
      }
    });
    this.setState({ open: false });
    try {
      const employee = codaEmployeesStore?.employeesList.find(employeeName => employeeName.email === email);
      const user : IRrpUser = { email, employeeId: employee?.id || -1, permissions };
      await userStore?.createOrUpdateUser(user);
      await userStore?.fetchUsers();
    } catch (error) {
      this.setState({submitError : `Error while setting permissions: ${error}`});
    }
  };

  private onInputCheck = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { usersPermissionAndState } = this.state;
    const inputValue = e.target.value;
    const newArray = usersPermissionAndState.map(element => {
      return (element.key === inputValue) ? { key: element.key, value: element.value, isChecked: !element.isChecked } : element;
    });
    this.setState({
      usersPermissionAndState: newArray,
    });
  };


  public render(): React.ReactNode {
    const { email, family_name, given_name, location, permissions, codaEmployeesStore } = this.props;
    const { usersPermissionAndState, open, submitError } = this.state;

    const usersPermissions = _.map(permissions).join(', ');
    const consultantInfo = codaEmployeesStore?.employeesList.find(employee => employee.email === email);
    const consultantImg = consultantInfo ? consultantInfo.image_url : '';
    const name = `${given_name} ${family_name}`;

    const permissionsCheckbox = usersPermissionAndState.map((permissionData) => {
      return (
        <div key={permissionData.key}>
          <FormControlLabel
            control={
              (
                <Checkbox
                  checked={permissionData.isChecked}
                  value={permissionData.key}
                  onChange={this.onInputCheck}
                  classes={
                    {
                      checked: 'fm-green',
                    }
                  }
                />
              )
            }
            label={permissionData.value}
          />
        </div>
      );
    });

    return (
      <>
        <GlobalStyle />
        <div className="list-container">
          <div>
            <List style={{ padding: '0' }}>
              <ListItem>
                <ListItemIcon>
                  <Avatar
                    src={consultantImg}
                    classes={
                      {
                        root: 'permissions-avatar',
                      }
                    }
                  />
                </ListItemIcon>
                <ListItemText>
                  <div className="avatar-name-list">{name}</div>
                  <div className="avatar-email-list">{email}</div>
                  <div className="avatar-deal-origin-list">{location}</div>
                </ListItemText>
              </ListItem>
            </List>
          </div>
          <div className="center-contents">
            <div>
              <div style={{ fontSize: '16px', lineHeight: '24px' }}>{usersPermissions}</div>
            </div>
          </div>
          <div className="center-contents" style={{ width: '150px', flex: '0 0 auto' }}>
            <div>
              <Button className="button-primary" onClick={this.onOpenDialog} variant="contained">
                Change
              </Button>
            </div>
          </div>
          <Dialog
            open={open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {name}
              {' '}
              Permissions
            </DialogTitle>
            <DialogContent>
              <div className="date-selection-checkbox-container">{permissionsCheckbox}</div>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={this.onChangePermission} color="primary" autoFocus>
                Change
              </Button>
            </DialogActions>
          </Dialog>
          <DialogBox
            open={!!submitError}
            handleModalState={this.clearErrorModal}
            body={submitError || ''}
            title="Error"
          />
        </div>
      </>
    );
  }
}

export default UserPermissions;
