/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import fetch from 'isomorphic-fetch';
import Params from './Params';
import Request from './Request';

export default class Ajax {
  public requestInterceptors : ((request: Request) => Request)[] = [];

  public addRequestInterceptor(interceptor: (request: Request) => Request) : void {
    this.requestInterceptors.push(interceptor);
  }

  public resetRequestInterceptors()  : void {
    this.requestInterceptors = [];
  }

  public async get(url: string, params?: Params, headers?: Params) : Promise<Response> {
    const method = 'GET';
    return this.send(
      new Request({
        headers,
        method,
        params,
        url,
      })
    );
  }

  public async post(url: string, body?: any, params?: Params, headers?: Params) : Promise<Response> {
    const method = 'POST';
    return this.send(
      new Request({
        body,
        headers,
        method,
        params,
        url,
      })
    );
  }

  public async put(url: string, body?: any, params?: Params, headers?: Params) : Promise<Response> {
    const method = 'PUT';
    return this.send(
      new Request({
        body,
        headers,
        method,
        params,
        url,
      })
    );
  }

  public async delete(url: string, body?: any, params?: Params, headers?: Params) : Promise<Response> {
    const method = 'DELETE';
    return this.send(
      new Request({
        body,
        headers,
        method,
        params,
        url,
      })
    );
  }

  public async send(req: Request) : Promise<Response> {
    let request = req;
    return new Promise((resolve, reject) => {
      try {
        for (let i = 0, len = this.requestInterceptors.length; i < len; i += 1) {
          request = this.requestInterceptors[i](request);
        }

        const methodStr: string = request.method.toUpperCase();
        const options: RequestInit = {
          body: request.body? JSON.stringify(request.body) : undefined,
          headers: {'Content-Type' : 'application/json'},
          method: methodStr,
        };

        if ((methodStr === 'GET' || methodStr === 'head') && request.body) {
          console.warn(`GET and HEAD requests cannot con't have the body.
          Body would be deleted for ${methodStr}:${request.url}`);
        } 
        
        request.headers.forEach((key, value) => {
          if (options.headers) {
            options.headers[key] = value;
          }
        });

        let {url} = request;
        if (request.params.size) {
          url = `url?${request.params
            .map((key, value) => {
              return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
            })
            .join('&')}`;
        }

        fetch(url, options)
          .then(response => {
            resolve(response);
          });
      } catch (e) {
        console.log('Cached reject', e);
        console.warn(`Request "${request.method.toUpperCase()}:${request.url}" was canceled by interceptor.`);
        reject();
      }
    });
  }
}
