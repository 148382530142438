import { action, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import Request from 'net/Request';
import Api from '../../net/Api';
import Project, { IProjectsJson } from '../models/Project';

interface IProjectCodes {
  code: string;
  name: string;
  id: number | string;
}

export default class ProjectsStore {
  @observable
  public projectsList: IObservableArray<Project> = observable([]);

  @observable
  public projectCodes: IProjectCodes[] = [];

  @observable
  public projectCodesLabel: string[] = [];

  constructor() {
    makeObservable(this);
  }

  public static createStore(): ProjectsStore {
    return new ProjectsStore();
  }

  @action('ProjectsStore:connect')
  public async connect(data: {type: string; id_token: string}) : Promise<void> {
    const apiUrl = process.env.REACT_APP_SIXPAQ_API_URL || '';
    const res = await Api.getInstance().post(`${apiUrl}/connect`, data);
    const json = await res.json();

    Api.getInstance().addRequestInterceptor((request: Request) : Request => {
      request.headers.set('authorization', `bearer ${json.token}`);
      return (request);
    });

    const req = await Api.getInstance().get(`${apiUrl}/projects/active`);
    const projects = await req.json();
    runInAction(() => {
      const tempProjectCodes: IProjectCodes[] = [];
      const tempProjectCodesLabel: string[] = [];
      projects.projects.forEach((project: IProjectsJson) => {
        const {id} = project;
        const {code} = project;
        const {name} = project;

        tempProjectCodes.push({ code, name, id });
        tempProjectCodesLabel.push(`${code} | ${name}`);
      });
      this.projectCodes = tempProjectCodes;
      this.projectCodesLabel = tempProjectCodesLabel;
    });

    this.projectsList = projects.projects.map((p: IProjectsJson) => new Project(p));
    Api.getInstance().resetRequestInterceptors();
  }
}
