import Paper from '@material-ui/core/Paper';
import { inject, observer } from 'mobx-react';
import React from 'react';

import { IRrpMonth } from 'interfaces/IRrpMonth';
import { Rrp, RrpStore } from '../../../../../stores';
import Pagination from '../../../../Common/Pagination';
import ApprovalQueueList from '../ApprovalQueueList';

interface IProps {
  rrpStore: RrpStore;
  tableData: IRrpMonth;
}

interface IRecentActivitiesState {
  pageNumber: number;
  itemsPerPage: number;
}

@inject('rrpStore')
@observer
class PendingRequestsTables extends React.Component<IProps, IRecentActivitiesState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
      itemsPerPage: 5,
      pageNumber: 1,
    };
  }

  public nextPage = (totalPages: number): void => {
    const { pageNumber } = this.state;
    if (pageNumber === totalPages) {
      return;
    }

    this.setState({ pageNumber: pageNumber + 1 });
  };

  public prevPage = (): void => {
    const { pageNumber } = this.state;
    if (pageNumber === 1) {
      return;
    }

    this.setState({ pageNumber: pageNumber - 1 });
  };


  public render(): React.ReactNode {
    const { rrpStore, tableData } = this.props;
    const { month, data } = tableData;
    const { itemsPerPage, pageNumber } = this.state;
    const { getEntriesInView } = rrpStore;

    const offset = (pageNumber - 1) * itemsPerPage;
    const itemsInView = getEntriesInView(data, offset, itemsPerPage);
    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    return (
      <div className="approval-queue">
        <Paper className="paper-padding">
          <div className="table-title">
            {month}
          </div>
          <div className="flex-row-table">
            <div className="flex-row-table-header">
              <div className="first-header-column-no-flex">Consultant</div>
              <div>Allocation</div>
              <div>Requested By</div>
              <div className="last-column" />
            </div>
            <div className="flex-row-table-body">
              {
                itemsInView.map((entry: Rrp) => {
                  return <ApprovalQueueList key={entry.id} data={entry} />;
                })
              }
            </div>
            <div className="flex-row-table-footer">
              <Pagination
                pageNumber={pageNumber}
                totalPages={totalPages}
                prevPage={this.prevPage}
                nextPage={() => this.nextPage(totalPages)}
              />
            </div>
          </div>
        </Paper>
      </div>
    );
  }

}

export default PendingRequestsTables;
