import { css } from 'styled-components'; 

const numberInputStyles = css`
  .numTextField::after {
    content: ' ';
    display: block;
    background-color: white;
    height: 96%;
    width: 15px;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export default numberInputStyles;
