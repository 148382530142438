import { css } from 'styled-components';

const reportsStyles = css`
  .total-hours {
    background-color: #eae6e6;
  }

  .consultant-name-subtitle {
    color: #a2a2a2;
  }

  .header-padding {
    padding: 0px 16px;
  }

  @media screen and (max-width: 991px) {
    .reports {
      .flex-row {
        display: block;
        button {
          margin-top: 20px;
        }
      }
      .list-container {
        display: block;
        text-align: center;
        > div {
          display: block;
          width: 100% !important;
          flex: none;
        }
        ul {
          li {
            display: block;
            div {
              display: block;
              margin: 0 auto;
              text-align: center;
              margin: 0 auto;
            }
          }
        }
        .deal-origin {
          &:before {
            content: 'Deal Origin';
            display: block;
            font-weight: bold;
            margin-top: 20px;
            font-size: 16px;
            color: black;
          }
        }
        .project-code {
          &:before {
            content: 'Deal Origin';
            display: block;
            font-weight: bold;
            margin-top: 20px;
            font-size: 16px;
            color: black;
          }
        }
        .allocation {
          &:before {
            content: 'Allocation';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
        .requested-by {
          &:before {
            content: 'Requested By';
            display: block;
            font-weight: bold;
            margin-top: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .filter-view {
      display: block;
    }
  }
`;

export default reportsStyles;
