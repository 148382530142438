import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Draft, { draftToRaw, rawToDraft } from 'react-wysiwyg-typescript';
import { createGlobalStyle } from 'styled-components';

import { AccountStore, CodaEmployeesStore, FirebaseStore, RrpStore, UserStore, SettingsStore } from 'stores';
import { IEmployeesWithPermissions } from 'interfaces/IEmployeesWithPermissions';
import DialogBox from 'components/Common/DialogBox';
import UserPermissions from './Blocks/UserPermissions';
import settingsStyles from './Settings.styles';

interface IProps {
  accountStore: AccountStore;
  codaEmployeesStore: CodaEmployeesStore;
  firebaseStore: FirebaseStore;
  rrpStore: RrpStore;
  settingsStore: SettingsStore;
  userStore : UserStore;
}

interface IState {
  editorState: Draft.EditorState | undefined;
  viewByFilter: string;
  submittedInfo: string | undefined;
}

const GlobalStyle = createGlobalStyle`${settingsStyles}`;

@inject('accountStore', 'codaEmployeesStore', 'firebaseStore', 'rrpStore', 'settingsStore', 'userStore')
@observer
class Settings extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      editorState: undefined,
      viewByFilter: 'user-with-permission',
      submittedInfo: undefined
    };
  }

  public componentDidMount(): void {
    const { settingsStore } = this.props;
    const template = settingsStore.emailRawTemplate;
    this.setState({
      editorState: rawToDraft(template),
    });
  }

  private onEditorChange = (editorState: Draft.EditorState): void => {
    this.setState({ editorState });
  };

  private onSaveEditor = async (): Promise<void> => {
    const { settingsStore } = this.props;
    const { editorState } = this.state;
    let plaintext = '';

    // Note: the value stored in the DB as plaintext will have double backslash in order to support the cloud function
    // json formatting
    const content = draftToRaw(editorState).replace(/\\n/g, '\\\\n');
    if (content) {
      const {blocks} = JSON.parse(content);
      plaintext = blocks.map((block: { text: string; }) => (!block.text.trim() && '\n') || block.text).join('\\n\\n');
    }
    await settingsStore.updateEmailTemplate(draftToRaw(editorState), plaintext);
    this.setState({submittedInfo : 'Email template updated.'});
  };

  private handleViewByFilter = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.setState({
      viewByFilter: event.target.value,
    });
  };

  private getEmployeesToDisplay = (): IEmployeesWithPermissions[] => {
    const { userStore, codaEmployeesStore } = this.props;
    const { viewByFilter } = this.state;

    const employeesWithPermissions: IEmployeesWithPermissions[] = codaEmployeesStore.employeesList.map((employee) => {
      const user = _.find(userStore.users, ['email', employee.email]);
      const permissions = user ? user.permissions : [];
      return {
        ...employee,
        permissions,
      };
    });

    return viewByFilter === 'all'
      ? employeesWithPermissions : _.filter(employeesWithPermissions, (employee) => { return employee.permissions.length > 0; });
  };

  private clearInfoModal = (): void => {
    this.setState({ submittedInfo: undefined });
  };

  public render(): React.ReactNode {
    const { viewByFilter, editorState, submittedInfo } = this.state;
    const sortedEmployees = _.sortBy(this.getEmployeesToDisplay(), 'given_name');

    return (
      <div className="paper-padding">
        <GlobalStyle />
        <div className="user-settings">
          <Paper className="paper-padding">
            <div className="table-header-text">
              Users
            </div>
            <div className="flex-row radio-padding">
              <div className="sort">
                <span>View by:</span>
                <span className="filter-view">
                  <Radio
                    aria-label="User with Permission"
                    checked={viewByFilter === 'user-with-permission'}
                    name="radio-button-demo"
                    onChange={this.handleViewByFilter}
                    value="user-with-permission"
                  />
                  <span>Users with Permission</span>
                </span>
                <span className="filter-view">
                  <Radio
                    aria-label="All"
                    checked={viewByFilter === 'all'}
                    name="radio-button-demo"
                    onChange={this.handleViewByFilter}
                    value="all"
                  />
                  <span>All</span>
                </span>
              </div>
            </div>
            <div className="flex-row-table">
              <div className="flex-row-table-header">
                <div className="first-header-column-no-flex">User</div>
                <div>Permissions</div>
                <div style={{ width: '150px', flex: 'none' }} />
              </div>
              <div className="flex-row-table-body">
                {
                  sortedEmployees.map((data) => {
                    return (
                      <UserPermissions
                        key={data.email}
                        permissions={data.permissions}
                        email={data.email}
                        family_name={data.family_name}
                        given_name={data.given_name}
                        location={data.location}
                      />
                    );
                  })
                }
              </div>
            </div>
          </Paper>
          <Paper className="paper-padding top-space-md">
            <div className="table-header-text">
              Email
              <div className="flex-row-table">
                <div className="flex-row-table-header email-table-header">
                  <div className="email-template-header">
                    Email Template:
                  </div>
                  <div className="email-controls">
                    <Button className="button-primary" variant="contained" onClick={this.onSaveEditor}>
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-row-table">
              <Draft editorState={editorState} onEditorStateChange={this.onEditorChange} />
            </div>
          </Paper>
          <DialogBox
            open={!!submittedInfo}
            handleModalState={this.clearInfoModal}
            body={submittedInfo || ''}
            title="Settings"
          />
        </div>
      </div>
    );
  }
}

export default Settings;
