import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import styles from '../BlocksCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface IProps {
  data: IReportDataJson;
}

const ConsultantsViewRows: React.FC<IProps> = (props) => {
  const { data } = props;
  const { hours, projectCode, dealOrigin } = data;

  return (
    <>
      <GlobalStyle />
      <div className="list-container">
        <div className="center-contents">
          <List style={{ padding: '0' }}>
            <ListItem>
              <ListItemText>
                {projectCode}
              </ListItemText>
            </ListItem>
          </List>
        </div>
        <div className="center-contents">{dealOrigin}</div>
        <div className="center-contents">
          {hours}
          {' '}
          Hours          
        </div>
      </div>
    </>
  );
};

export default ConsultantsViewRows;
