import * as React from 'react';

import { IReportDataJson } from 'interfaces/IReportDataJson';
import TotalAllocationCalculator from '../../../../../utils/TotalAllocationCalculator';

interface IProps {
  entries: IReportDataJson[];
}

const TotalHoursRow: React.FC<IProps> = (props) => {
  const { entries } = props;
  const allocations = new TotalAllocationCalculator(entries);
  const hours = allocations.sum('hours');

  return (
    <div className="list-container total-hours">
      <div className="center-contents"><b>Total</b></div>
      <div className="center-contents" />
      <div className="center-contents">
        <b>
          {hours}
          {' '}
          Hours
        </b>
      </div>
    </div>
  );
};

export default TotalHoursRow;
