import firebase from 'firebase/app';
import 'firebase/firestore';
import { observable, action, makeObservable } from 'mobx';

export default class FirebaseStore {
  @observable
  public isSixpaqInitialisedPromise: Promise<void>;

  @observable
  public firebase: firebase.app.App;

  @observable
  public sixpaqToken = '';

  public static createStore(): FirebaseStore {
    return new FirebaseStore();
  }  

  constructor() {
    // Initialize firebase
    const apiKey = process.env.REACT_APP_FB_KEY || '';
    const authDomain = process.env.REACT_APP_FB_AUTHDOMAIN || '';
    const databaseURL = process.env.REACT_APP_FB_DB_URL || '';
    const projectId = process.env.REACT_APP_FB_PROJECT_ID || '';
    const storageBucket = process.env.REACT_APP_FB_STORAGE_BUCKET || '';
    const messagingSenderId = process.env.REACT_APP_FB_MESSAGING_ID || '';

    const config = {
      apiKey,
      authDomain,
      databaseURL,
      messagingSenderId,
      projectId,
      storageBucket,
    };

    this.firebase = firebase.initializeApp(config);

    // Initialize sixpaq and retrieve token for connecting later on
    const apiKeySp = process.env.REACT_APP_SIXPAQ_API_KEY || '';
    const authDomainSp = process.env.REACT_APP_SIXPAQ_AUTHDOMAIN || '';
    const databaseURLSp = process.env.REACT_APP_SIXPAQ_DB_URL || '';
    const projectIdSp = process.env.REACT_APP_SIXPAQ_PROJECT_ID || '';
    const storageBucketSp = process.env.REACT_APP_SIXPAQ_STORAGE_BUCKET || '';
    const messagingSenderIdSp = process.env.REACT_APP_SIXPAQ_MESSAGING_ID || '';
  
    const configSixpaq = {
      apiKey: apiKeySp,
      authDomain: authDomainSp,
      databaseURL: databaseURLSp,
      messagingSenderId: messagingSenderIdSp,
      projectId: projectIdSp,
      storageBucket: storageBucketSp,
    };

    const sixpaq = firebase.initializeApp(configSixpaq, 'sixpaq');

    const sixpaqPwd = process.env.REACT_APP_SIXPAQ_PWD || '';
    const sixpaqUser = process.env.REACT_APP_SIXPAQ_USER || '';

    this.isSixpaqInitialisedPromise = new Promise((resolve, reject) => { 
      sixpaq
        .auth()
        .signInWithEmailAndPassword(sixpaqUser, sixpaqPwd)
        .then((val: firebase.auth.UserCredential) => {
          if (!val.user) {
            reject(new Error('Sixpaq auth user null'));
            return;
          }
          val.user.getIdToken()
            .then((token) => {
              this.sixpaqToken = token;
              sixpaq
                .auth()
                .signOut()
                .then(() => {
                  resolve();
                });
            });
        })
        .catch(error => {
          reject(error);
        });
    });

    makeObservable(this);
  }

  @action('FirebaseStore:logoutUser')
  public async logoutUser() : Promise<void> {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        console.log('logged out');
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
