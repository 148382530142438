import { css } from 'styled-components';

const settingsStyles = css`
  .table-header-text {
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    padding: 8px;
  }

  .email-table-header {
    font-weight: normal;
  }

  .email-template-header {
    color: rgba(0,0,0,0.5438);
    flex: none;
    font-size: 14px;
    text-align: left;
    width: 50%;
    font-weight: normal;
  }

  .email-controls {
    float: right;
    text-align: right;
  }

  .radio-padding {
    padding: 16px 16px 16px 0px;
  }
`;

export default settingsStyles;
