import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

import { IEmployeeResponse } from '../../../../../interfaces/IEmployeeResponse';
import { IReportDataJson } from '../../../../../interfaces/IReportDataJson';
import NoDataPlaceholder from '../../../../Common/NoDataPlaceholder';
import DealOriginViewRows from '../../Blocks/DealOriginViewRows';
import TotalHoursRow from '../../Blocks/TotalHoursRow';
import styles from '../ViewsCommon.styles';

const GlobalStyle = createGlobalStyle`${styles}`;

interface ITableProps {
  origin: string | number;
  data: IReportDataJson[];
}

interface IViewProps {
  employeeList: IEmployeeResponse[];
  filteredData: IReportDataJson[];
  dealOriginList: string[];
}

class DealOriginView extends React.Component<IViewProps> {

  public renderTables = (tableData: ITableProps[]) : React.ReactNode => {
    const { employeeList } = this.props;

    return tableData.length > 0 ? tableData.map((table: ITableProps) => {
      const getNamesandEmails = table.data.map(data => ({
        ...data,
        email: data.consultant.email
      }));

      const sortTableData = _.sortBy(getNamesandEmails, 'email');

      return table.data.length !== 0 ? (
        <Paper key={table.origin} className="paper-padding result-tables">
          <div className="table-title">
            {table.origin}
          </div>
          <div className="flex-row-table">
            <div className="table-container">
              <div className="flex-row-table-header">
                <div className="first-header-column-no-flex">Consultant</div>
                <div>Project Code</div>
                <div>Allocation</div>
              </div>
            </div>
            <div className="flex-row-table-body">
              {
                sortTableData.map(
                  (row: IReportDataJson) => (
                    <DealOriginViewRows employeeList={employeeList} key={row.id} data={row} />
                  )
                )
              }
              <TotalHoursRow entries={table.data} />
            </div>
          </div>
        </Paper>
      ) : null;
    }) : <NoDataPlaceholder />;
  };

  public render() : React.ReactNode {
    const { filteredData, dealOriginList } = this.props;

    let viewData: ITableProps[] = [];

    viewData = dealOriginList.map((origin) => {
      const entries = _.filter(filteredData, ['dealOrigin', origin]);
      return { origin, data: entries };
    });
    viewData = _.filter(viewData, origin => origin.data.length > 0);

    return (
      <>
        <GlobalStyle />
        {this.renderTables(viewData)}
      </>
    );
  }
}

export default DealOriginView;
