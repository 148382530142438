import { css } from 'styled-components'; 

const styles = css`
  .permissions-avatar {
    backgroundColor: #e0e0e0;
    height: 56px;
    width: 56px;
    margin-right: 10px;      
  }

  @media screen and (max-width: 730px) {
    .user-settings {
      .flex-row-table-header {
        display: none;
      }
      .flex-row-table-body {
        .list-container {
          display: block;
          .center-contents {
            display: block;
          }
          > div {
            width: 100% !important;
            text-align: center !important;
            ul {
              li {
                display: block !important;
                [class*='MuiAvatar-root-'] {
                  margin: 0 auto;
                }
                div {
                  display: block;
                  text-align: center;
                  margin: 0 auto !important;
                }
              }
            }
          }
        }
      }
    }
  }  
`;

export default styles;
